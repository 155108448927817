import { ADD_TO_REQUEST, SELECT_REQUEST, UPDATE_SELECT_REQUEST,
  CLEAR_SELECT_REQUEST, REMOVE_FROM_REQUEST, UPDATE_REQUEST,
  IS_READ_CONDITION, REQUEST_UID, SET_PARTNER_DATA } from './actionsTypes';

export const addToRequest = (request: any) => {
  return {
    type: ADD_TO_REQUEST,
    payload: request,
  };
};

export const updateRequest = (articles: any) => {
  return {
    type: UPDATE_REQUEST,
    payload: articles
  };
};

export const removeFromRequest = (articleUid: string) => {
  return {
    type: REMOVE_FROM_REQUEST,
    payload: articleUid
  };
};

export const selectRequest = (request: any) => {
  return {
    type: SELECT_REQUEST,
    payload: request,
  };
};

export const requestUid = (request: any) => {
  return {
    type: REQUEST_UID,
    payload: request,
  };
};

export const updateSelectRequest = (request: any) => {
  return {
    type: UPDATE_SELECT_REQUEST,
    payload: request,
  };
};

export const clearSelectRequest = () => {
  return {
    type: CLEAR_SELECT_REQUEST,
    payload: null,
  };
};

export const isReadRequestCondition = (isRead: boolean) => {
  return {
    type: IS_READ_CONDITION,
    payload: isRead,
  };
};

export const setPartnerParams = (params: any) => {
  return {
    type: SET_PARTNER_DATA,
    payload: params,
  };
};
