import { 
  LOGIN_SUCCESS, LOGIN_USER, LOGOUT_USER,
  LOGOUT_USER_SUCCESS, API_ERROR,
} from './actionsTypes';

export const loginUser = (user: any, navigate: any) => {
  return {
    type: LOGIN_USER,
    payload: { user, navigate }
  };
};

export const loginSuccess = (user: any) => {
  return {
    type: LOGIN_SUCCESS,
    payload: user
  };
};

export const logoutUser = (navigate: any) => {
  localStorage.removeItem('authUser');
  navigate('/');
  navigate(0);
  return {
    type: LOGOUT_USER,
    payload: navigate
  };
};

export const logoutUserSuccess = () => {
  localStorage.removeItem('authUser');
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {}
  };
};

export const apiError = (error: any) => {
  return {
    type: API_ERROR,
    payload: error
  };
};
