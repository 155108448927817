import { Container, Card, Button, Form, Col, Row} from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Stepper, Step } from 'react-form-stepper';

// Start import helpers 
import { getRandomId, stepperStyleConfig, stepperConnectorStyleConfig, majorCategoriesImg } from 'helpers/common_helper';
import { selectRequest } from 'store/request/actions';
import { toastValues } from 'common/toastValues';
import { getBnsApi } from 'helpers/api_helpers';
import { BRAND_API} from 'constants/apiEndPoints/bnsApi/brandEndpoints';
import { CATEGORY_API } from 'constants/apiEndPoints/bnsApi/categoriesEndpoints';
import {
    parentCategoryMale,
    parentCategoryFemale,
    categoryAccessory,
    categoryBag,
    categorySwimwear,
    categoryTop,
    productSubTypeJacket,
    productSubTypeSuit,
    commonCategory,
} from '../assets/image/image';

// Start import components
import ProductSubTypeModal from 'components/common/ProductSubTypeModal';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const CategoryPage = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const request = useSelector(({ Request }: { Request: any }) => Request);
    const [selectedRequest, setSelectedRequest] = useState<any>({});
    const [brands, setBrands] = useState<any>([]);
    const [category, setCategory] = useState<any>([]);
    const [brand, setBrand] = useState<any>({});
    const [mainCategory, setMainCategory] = useState<any>('');
    const [majorCategoryList, setMajorCategoryList] = useState<any>([]);
    const [majorCategory, setMajorCategory] = useState<any>('');
    const [minorCategoryList, setMinorCategoryList] = useState<any>([]);
    const [minorCategory, setMinorCategory] = useState<any>('');
    const [isShowMinorCategoryModal, setIsShowMinorCategoryModal] = useState<boolean>(false);
    const [activeStep, setActiveStep] = useState<number>(0);
    const [isCategoryReload, setIsCategoryReload] = useState<boolean>(false);

    // Start lifecycle methods
    useEffect(() => {
        fetchBrands(); // fetch all brands'
        fetchCategory(); // fetch al Category
        setSelectedRequest(request?.selectRequest || {});
    }, []);

    useEffect(() => {
        if(selectedRequest?.uid) {
            setActiveStep(2);
            setBrand({label: selectedRequest?.brand, value: selectedRequest?.brand})
            setMainCategory(selectedRequest?.main_category);
            setMajorCategory(selectedRequest?.major_category);
            setMinorCategory(selectedRequest?.minor_category);
        }
    }, [selectedRequest, brands]);

    const stepsList = [
        { label: t('stepper_1'), position: 0, classes:"brand-step" },
        { label: t('stepper_2'), position: 1, classes:"main-category-step" },
        { label: t('stepper_3'), position: 2, classes:"sub-category-step" }
    ];

    //Start fetching handler/helpers
    const fetchBrands = async () => {
        try {
            const brandRes = await getBnsApi(BRAND_API.fetch_all());
            for(let i = 0; i < brandRes.data.values.length; ++i){
                const obj:any = {};
                obj.value = brandRes.data.values[i];
                obj.label = brandRes.data.values[i];
                setBrands((curr:any) => [...curr, obj])
            }
            setIsCategoryReload(true);
            setTimeout(()=> { setIsCategoryReload(false); });
        } catch (error) {
            console.log('error:', error);
        }
    };

    const fetchCategory= async () => {
        try {
            const categoryRes = await getBnsApi(CATEGORY_API.fetch_all());
            setCategory(categoryRes.data.main_categories);
            setMajorCategoryList(categoryRes.data.main_categories[0].major_categories)
        } catch (error) {
            console.log('error:', error);
        }
    };


    const onSearchSelectChangeHandler = (item:any) => {
        setBrand(item);
        setActiveStep(1);
        setMajorCategory('');
        setMainCategory('');
    }

    const onChangeMainCategoryHandler = (list:any, name:any, e:React.ChangeEvent<HTMLInputElement>) => {
        if(majorCategory && minorCategory){
            setMajorCategory('');
            setMinorCategory('');
            setMinorCategoryList([]);
        }

        setMajorCategoryList(list);
        setMainCategory(name);
        setActiveStep(2);
    }

    const onChangeMajorCategoryHandler = (list:any, key:any, e:React.ChangeEvent<HTMLInputElement>) => {
        setMinorCategory('');
        setMinorCategoryList(list);
        setIsShowMinorCategoryModal(!isShowMinorCategoryModal);
        setMajorCategory(key);
    }

    const onSubmitBrandAndCategory = () => {
        if(!brand.value) {
            toast.error(`Please select Brand!`, toastValues);
            return;
        } else if(!mainCategory){
            toast.error(`Please select Main Category!`, toastValues);
            return;
        } else if(!majorCategory){
            toast.error(`Please select Major Category!`, toastValues);
            return;
        }

        const articleUid = getRandomId(12);
        const article = {
            uid: articleUid,
            requestUid: request?.uid,
            name: `${brand.value} ${minorCategory || majorCategory}`,
            main_category: mainCategory,
            major_category: majorCategory,
            minor_category: minorCategory,
            brand: brand.value,
            status: "Pending",
        };
        dispatch(selectRequest(article));
        navigate('/photo');
    }

    const isFormInvalid = () => {
        return !!(!brand.value || !mainCategory || !majorCategory);
    }

    const filterBrands = (inputValue: string) => {
        return brands.filter((i:any) =>
            i.label.toLowerCase().includes(inputValue.toLowerCase())
        );
    };

    const brandOptions = (inputValue:any, callback:any) => {
        setTimeout(() => {
            callback(filterBrands(inputValue));
        }, 1000);
    };
// End events handler

    return (
        <>
            <Container fluid className="background-white container-min-height pt-4 pb-5">
                <Card.Title className="page-title">{t('choose_category_and_brand')}</Card.Title>

                <Stepper activeStep={activeStep}
                         className="request-create-stepper"
                         connectorStateColors={true}
                         styleConfig={stepperStyleConfig}
                         connectorStyleConfig={stepperConnectorStyleConfig}
                >
                    {
                        stepsList.map((stepItem:any, index:number)=> {
                            return ( <Step key={`stepHeader_${index}`} className={`${stepItem?.classes || ''}`} label={stepItem.label} /> )
                        })
                    }
                </Stepper>

                <div className="stepper-container-box">
                    <div className="stepper-content">
                        {/* Start brand fields  */}
                        <Row>
                            <Col lg={3} md={3} xs={12}> </Col>
                            <Col lg={6} md={6} xs={12}>
                                <div className="form-field brand-search-field">
                                    {
                                        !isCategoryReload? <AsyncSelect
                                            placeholder={`${t('select_brand')}...`}
                                            loadingMessage={() => `${t('searching')}...`}
                                            noOptionsMessage={() => `${t('no_options')}`}
                                            cacheOptions
                                            value={brand?.value? brand: ''}
                                            loadOptions={brandOptions}
                                            defaultOptions
                                            onChange={(item:any) => onSearchSelectChangeHandler(item) }
                                        />:<></>
                                    }

                                </div>
                            </Col>
                            <Col lg={3} md={3} xs={12}> </Col>
                        </Row>

                        {/* Start main category items field */}
                        {
                            !!(activeStep === 1 || activeStep === 2)? <Row className="my-5 d-flex justify-content-center">
                                {
                                    category.map((mainCategoryItem:any, index:number)=> {
                                        return (
                                            <Col key={`${mainCategoryItem.key}_${index}`} lg={2} md={3} xs={6}>
                                                <Form.Check className="category-card" type="radio" id={`${mainCategoryItem.key}-category`} >
                                                    <Form.Check.Input type="radio" name="mainCategory" className="bns-checkbox bns-card round"
                                                                      checked={mainCategoryItem.key === mainCategory}
                                                                      onChange={(e) => onChangeMainCategoryHandler(mainCategoryItem.major_categories, mainCategoryItem.key, e)}
                                                    />
                                                    <Form.Check.Label>
                                                        <Card className="category-card-item">
                                                            <div className="category-card-item-content">
                                                                <img src={mainCategoryItem.key === 'men' ? parentCategoryMale : parentCategoryFemale} title={mainCategoryItem.name}  />
                                                                <p>{mainCategoryItem.name}</p>
                                                            </div>
                                                        </Card>
                                                    </Form.Check.Label>
                                                </Form.Check>
                                            </Col>
                                        )
                                    })
                                }
                            </Row>:<></>
                        }

                        {/* Start major category items field */}
                        {
                            activeStep === 2?
                                <div className="d-flex justify-content-center">
                                    <Row className="my-3 d-flex justify-content-center" style={{ maxWidth: "1020px"}}>
                                        {/* <Col lg={2} md={3} xs={12}> </Col>
                  <Col lg={8} md={6} xs={12}>
                  <Row className="d-flex justify-content-center"> */}
                                        {
                                            majorCategoryList.map((majorCategoryItem:any, index:number)=> {
                                                return (
                                                    <Col key={`${majorCategoryItem.key}_${index}`} className="mb-4 d-flex justify-content-center" lg={3} md={4} xs={6}>
                                                        <Form.Check className="category-card" type="radio" id={`${majorCategoryItem.key}-major-category`} >
                                                            <Form.Check.Input type="radio"
                                                                              className="bns-checkbox bns-card round"
                                                                              name="majorCategory"
                                                                              checked={majorCategoryItem.key === majorCategory}
                                                                              onChange={(e) => onChangeMajorCategoryHandler(majorCategoryItem.minor_categories, majorCategoryItem.key, e)}
                                                            />
                                                            <Form.Check.Label>
                                                                <Card className="category-card-item">
                                                                    <div className="category-card-item-content">
                                                                        <img src={majorCategoriesImg(majorCategoryItem.key)} title={majorCategoryItem.label}  />
                                                                        <p>{majorCategoryItem.name}</p>
                                                                    </div>
                                                                </Card>
                                                            </Form.Check.Label>
                                                        </Form.Check>
                                                    </Col>
                                                )
                                            })
                                        }
                                        {/* </Row>
                </Col>
                <Col lg={2} md={3} xs={12}> </Col> */}
                                    </Row>
                                </div>
                                :<></>
                        }
                    </div>
                </div>

                {/* Start continue button  */}
                {
                    activeStep === 2? <Row>
                        <Col lg={3} md={3} xs={12}> </Col>
                        <Col lg={6} md={6} xs={12}>
                            <div className="pb-3 text-center">
                                <Button disabled={isFormInvalid()} onClick={()=>onSubmitBrandAndCategory()} className="round-btn" variant="dark" size="sm"> {t('continue_img_selection')} </Button>
                            </div>
                        </Col>
                        <Col lg={3} md={3} xs={12}> </Col>
                    </Row>:<></>
                }
            </Container>

            {/* Start load components  */}
            {/*<ProductSubTypeModal*/}
            {/*    items={minorCategoryList}*/}
            {/*    setItems={setMinorCategoryList}*/}
            {/*    show={isShowMinorCategoryModal}*/}
            {/*    onHide={() => setIsShowMinorCategoryModal(!isShowMinorCategoryModal)}*/}
            {/*    setMinorcategory={setMinorCategory}*/}
            {/*/>*/}
        </>
    );
};

export default CategoryPage;
