import { Container, Card, Button, Form, Col, Row, Spinner} from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState , FormEvent} from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';

// Start helpers
import { isEmailValid } from 'common/validations';
import { toastValues } from 'common/toastValues';
import { post } from 'helpers/api_helpers';
import { AUTH_API } from 'constants/apiEndPoints/securityEndPoints';

const EmailInputPage = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { state } = useLocation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isOTPEmailInvalid, setIsOTPEmailInvalid] = useState<boolean>(true);
    const [OTPEmail, setOTPEmail] = useState<string>('');
    const [isRegistration, setIsRegistration] = useState<boolean>(state?.isRegistration || false);

    const onNavigatehandler =(e: FormEvent<EventTarget>, path: string): void => {
        if(path) navigate(path);
    };

// Start events handler
    const otpEmailHandler = (e: FormEvent<EventTarget>): void => {
        let target = e.target as HTMLInputElement;
        const value = target.value;
        setOTPEmail(value);
        setIsOTPEmailInvalid(!isEmailValid(value));
    };

    const sendOTPFormHandler = async () => {
        if(!OTPEmail) {
            toast.error('message.error.email_required', toastValues);
        }
        
        setIsLoading(true);

        try {
            if (OTPEmail && !isOTPEmailInvalid) {
                const formData = {
                    email: OTPEmail,
                    type: 'registration'
                };
                const res = await post(AUTH_API.sendOtp(), formData);
                if(res) {
                    toast.success(t('message.success.check_email'), toastValues);
                    setIsLoading(false);
                    navigate('/otp-verify',{ state: {...state, email: OTPEmail } });
                }
            }
        } catch (error: any) {
            setIsLoading(false);
            toast.error(error?.response?.data?.title, toastValues);
        }
    };
// End events handler

    return (
        <>
            <Container fluid className="background-white container-min-height">
                <Card.Title className="page-title fw-bold">{!isRegistration ? t('login'): t('registration')}</Card.Title>

                {/* Start product category */}
                <Row>
                    <Col lg={4} md={3} xs={12}></Col>
                    <Col lg={4} md={6} xs={12} className={"text-center"}>
                        <p className="text-center"> {t('registration_instruction_msg_one')} </p>
                        <p className="text-center">{t('registration_instruction_msg_two')}</p>
                        <Form.Group className="mb-5" controlId="formOrEmail">
                            <Form.Control type="email" placeholder={t('form.email.placeholder') || ''} defaultValue={OTPEmail} onChange={(e)=>otpEmailHandler(e)} />
                        </Form.Group>
                        {!isOTPEmailInvalid?<div className="d-flex justify-content-center">
                            <Button variant="dark" className={isLoading?'disabled':''} onClick={()=> isLoading?'':sendOTPFormHandler()}>
                                {isLoading ? (
                                    <><Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> {t('sending')}...</>
                                    ) : ( t('proceed_to_verification') )}
                            </Button>
                        </div>:null}
                    </Col>
                    <Col lg={4} md={3} xs={12}> </Col>
                </Row>
            </Container>
        </>
    );
};

export default EmailInputPage;
