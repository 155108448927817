import { Container, Card, Button, Form} from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Start import helpers
import { isReadRequestCondition, setPartnerParams } from 'store/request/actions';

// Start import components
import TermConditionModal from 'components/common/TermConditionModal';

// Start import images
import { stepOne, stepTwo, stepThree } from 'assets/image/image';
import { useTranslation } from 'react-i18next';

const GuidelinePage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const partnerId = searchParams?.get('partnerId');
  const partnerCoupon = searchParams?.get('partnerCoupon');
  const { t } = useTranslation();
  const checkboxRef = useRef<HTMLInputElement>(null);

  const { authUser } = useSelector(({ Login }: { Login: any }) => Login);
  const [isAuth, setIsAuth] = useState<boolean>(true);
  const [isAgreeTermCondition, setIsAgreeTermCondition] = useState<boolean>(false);
  const [isShowTermConditionModal, setIsShowTermConditionModal] = useState<boolean>(false);


  useEffect(()=>{
    setIsAuth(!!authUser);
    setPartnerParams({ partnerId, partnerCoupon });
  }, []);

  // Start events handlers
  const onChangeTermConditionHandler = (e:any) => {
    const isChecked = e.currentTarget.checked;
    setIsAgreeTermCondition(isChecked);
  };

  const onLinkClick = (e:any) => {
    e.preventDefault();
    if (checkboxRef.current) {
      checkboxRef.current.checked = !checkboxRef.current.checked;
      const isChecked = checkboxRef.current.checked
      setIsAgreeTermCondition(isChecked)
    }
    window.open("https://www.buddyandselly.com/en/purchase-conditions/", "_blank");
  }

  const onSubmitAgreeForm = () => {
    if(isAgreeTermCondition) {
      dispatch(isReadRequestCondition(true));
      const params:any = {partnerId, partnerCoupon};
      dispatch(setPartnerParams(params));
      navigate('/categories');
    }
  };

  const showTermCondition = (e:any) => {
    e.preventDefault();
    setIsShowTermConditionModal(!isShowTermConditionModal);
  };

  // End events handlers

  return (
      <>
        <Container fluid className="background-white container-min-height">
          <div className='d-flex justify-content-center'>
            <div style={{ width: "600px"}}>
              <Card.Title className="page-title">{t('how_its_done')}</Card.Title>
              <div className="how-to-list-group">
                <div className="how-to-list">
                  <div className="how-to-img-box mr-10">
                    <img src={stepOne} title="1" />
                  </div>
                  <div className="how-to-article-box">
                    <p>{t('how_its_done_msg_1')}</p>
                  </div>
                </div>
                <div className="how-to-list">
                  <div className="how-to-img-box mr-10">
                    <img src={stepTwo} title="2" />
                  </div>
                  <div className="how-to-article-box">
                    <p>{t('how_its_done_msg_2')}</p>
                  </div>
                </div>
                <div className="how-to-list">
                  <div className="how-to-img-box mr-10">
                    <img src={stepThree} title="3" />
                  </div>
                  <div className="how-to-article-box">
                    <p>{t('how_its_done_msg_3')}</p>
                  </div>
                </div>
              </div>

              <div className="how-to-form-term-box my-3 px-3">
                <Form.Check type="checkbox" id="term-condition">
                  <Form.Check.Input ref={checkboxRef} className="bns-checkbox" type="checkbox" onChange={(e) => onChangeTermConditionHandler(e)} name="term-condition" />
                  <Form.Check.Label>{t('terms_and_conditions_confirm')}
                    {/* <a onClick={(e)=>showTermCondition(e)}  href=""> {t('term_conditions.terms_and_conditions_modal_title')}</a> */}
                    <a target="_blank" href="https://www.buddyandselly.com/en/purchase-conditions/"> {t('term_conditions.terms_and_conditions_modal_title')}</a>
                  </Form.Check.Label>
                </Form.Check>
              </div>

              <div className="how-to-form-footer text-center pt-3 pb-5">
                <Button disabled={!isAgreeTermCondition} onClick={()=>onSubmitAgreeForm()} className="round-btn" variant="dark" size="sm">{t('term_conditions.guidelene_page_button')}</Button>
              </div>
            </div>
          </div>

        </Container>

        {/* Start load components  */}
        <TermConditionModal show={isShowTermConditionModal} onHide={() => setIsShowTermConditionModal(!isShowTermConditionModal)} />
      </>
  );
};

export default GuidelinePage;
