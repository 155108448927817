import axios from 'axios';
import accessToken from './jwt-token-access/accessToken';

//apply base url for axios
const API_URL = process.env.REACT_APP_API_BASE_URL;
const axiosApi = axios.create({
  baseURL: API_URL
});

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

//pass new generated access token here
const setAccessToken = (isNotReturnToken:boolean) => {
  const localeAuth: any = localStorage.getItem("authUser");
  const authUser = localStorage.getItem("authUser")
    ? JSON.parse(localeAuth)
    : null;

  const token = (authUser && authUser.accessToken) || "";
  const apiToken = token? `Bearer ${token}` : accessToken;
  if(!isNotReturnToken) {
    return apiToken;
  } else {
    axiosApi.defaults.headers.common['Authorization'] = apiToken;
  }
 
};

export async function get(url: string, config: any = {}) {
  setAccessToken(true);
  return await axiosApi.get(url, { ...config }).then((response) => response.data);
}

export async function post(url: string, data: any, config:any = {}) {
  //Here firstly check and upload for media file post request 
  //Here secondly post api common request
  if(!!(config && config?.isFile)){
    const authToken = setAccessToken(false);
    return await axios.post(`${API_URL}${url}`, data, {
      headers: { "Authorization": authToken, "Content-Type": "multipart/form-data" },
    });
  } else {
    setAccessToken(true);
    if(!(config && config?.isArray)) data = { ...data };
    return axiosApi.post(url, data, { ...config }).then((response) => response.data);
  }
}

export async function put(url: string, data: any, config:any = {}) {
  setAccessToken(true);
  return axiosApi.put(url, { ...data }, { ...config }).then((response) => response.data);
}

export async function patch(url: string, data: any, config:any = {}) {
  setAccessToken(true);
  return axiosApi.patch(url, { ...data }, { ...config }).then((response) => response.data);
}

export async function del(url: string, config = {}) {
  setAccessToken(true);
  return await axiosApi.delete(url, { ...config }).then((response) => response.data);
}


//this base url of main api from buddy and selly
//token of buddy and selly
const BNS_BASE_URL = process.env.REACT_APP_BNS_BASE_URL;
const bnsApiKey = process.env.REACT_APP_BNS_API_KEY;

const axiosBnsApi = axios.create({
  baseURL: BNS_BASE_URL
});

axiosBnsApi.defaults.headers.common['Authorization'] = bnsApiKey;

axiosBnsApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function getBnsApi(url: string, config: any = {}) {
  return await axiosBnsApi.get(url, { ...config }).then((response) => response.data);
}

export async function postBnsApi(url: string, data: any, config:any = {}) {
  return axiosBnsApi.post(url, { ...data }, { ...config }).then((response) => response.data);
}

export async function putBnsApi(url: string, data: any, config:any = {}) {
  return axiosBnsApi.put(url, { ...data }, { ...config }).then((response) => response.data);
}

export async function patchBnsApi(url: string, data: any, config:any = {}) {
  return axiosBnsApi.patch(url, { ...data }, { ...config }).then((response) => response.data);
}

export async function delBnsApi(url: string, config = {}) {
  return await axiosBnsApi.delete(url, { ...config }).then((response) => response.data);
}

//this base url of main api from buddy and selly Private Seller
//token of buddy and selly
const BNS_PRIVATE_SELLER_URL = process.env.REACT_APP_BNS_PRIVATE_SALLER_BASE_URL;
const BNS_PRIVATE_SELLER_API_KEY = process.env.REACT_APP_BNS_PRIVATE_SALLER_API_KEY;
const bnsPrivateSellerApiKey = `Token token=${BNS_PRIVATE_SELLER_API_KEY}`;

const axiosBnsPrivateSellerApi = axios.create({
  baseURL: BNS_PRIVATE_SELLER_URL
});

axiosBnsPrivateSellerApi.defaults.headers.common['Authorization'] = bnsPrivateSellerApiKey;

axiosBnsPrivateSellerApi.interceptors.response.use(
    (response) => response,
    (error) => Promise.reject(error)
);

export async function getBnsPrivateSellerApi(url: string, config: any = {}) {
  return await axiosBnsPrivateSellerApi.get(url, { ...config }).then((response) => response.data);
}

export async function postBnsPrivateSellerApi(url: string, data: any, config: any = {}) {
  return axiosBnsPrivateSellerApi.post(url, { ...data }, { ...config }).then((response) => response.data);
}

export async function putBnsPrivateSellerApi(url: string, data: any, config: any = {}) {
  return axiosBnsPrivateSellerApi.put(url, { ...data }, { ...config }).then((response) => response.data);
}

export async function patchBnsPrivateSellerApi(url: string, data: any, config: any = {}) {
  return axiosBnsPrivateSellerApi.patch(url, { ...data }, { ...config }).then((response) => response.data);
}

export async function deleteBnsPrivateSellerApi(url: string, config = {}) {
  return await axiosBnsPrivateSellerApi.delete(url, { ...config }).then((response) => response.data);
}
