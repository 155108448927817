import {
  Container,
  Card,
  Button,
  Col,
  Row,
  Form,
  InputGroup,
} from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { toastValues } from "common/toastValues";

// Start import components
import PhotoRestrictionsModal from "components/common/PhotoRestrictionsModal";
import PhotoUploader from "components/common/PhotoUploader";
import ImageUploadLoader from "components/common/ImageUploadLoader";

// Start import images
import {
  photoPlaceholderFront,
  photoPlaceholderBack,
  photoPlaceholder,
} from "assets/image/image";

// Start import helpers
import { clearSelectRequest, addToRequest } from "store/request/actions";
import { FILE_API } from "constants/apiEndPoints/fileEndpoints";
import { CART_API } from "constants/apiEndPoints/bnsApi/cartEndpoints";
import { post, del, putBnsApi, put } from "helpers/api_helpers";
import { useTranslation } from "react-i18next";
import { indexOf } from "lodash-es";
import { REQUEST_BNS_API } from "constants/apiEndPoints/bnsApi/requestEndpoints";
import { ARTICLE_API } from "constants/apiEndPoints/bnsApi/articleEndpoints";

const AdditionalInfos = () => {
  const [user, setUser] = useState<any>({});

  useEffect(() => {
    setUser(authUser);
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { state }: any = useLocation();
  const { selectRequest } = useSelector(
      ({ Request }: { Request: any }) => Request
  );
  const [frontImgLoading, setFrontImgLoading] = useState<boolean>(false);
  const [backImgLoading, setBackImgLoading] = useState<boolean>(false);
  const { authUser } = useSelector(({ Login }: { Login: any }) => Login);
  const [isShowTermModal, setIsShowTermModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(state?.isEdit || false);
  const [article, setArticle] = useState<any>(state?.article || {});
  const [fromPathname, setFromPathname] = useState<string>(
      state?.fromPathname || ""
  );
  const [frontImage, setFrontImage] = useState<any>();
  const [frontImageFileId, setFrontImageFileId] = useState<any>();
  const [isShowfrontImage, setIsShowfrontImage] = useState<boolean>(false);
  const [backImage, setBackImage] = useState<any>();
  const [backImageFileId, setBackImageFileId] = useState<any>();
  const [isShowbackImage, setIsShowbackImage] = useState<boolean>(false);
  const [isShowHeightWidth, setIsShowHeightWidth] = useState<boolean>(false);
  const [width, setWidth] = useState<any>("");
  const [height, setHeight] = useState<any>("");
  const [isShowAdditionalImageOne, setIsShowAdditionalImageOne] =
      useState<boolean>(false);
  const [additionalImageOneLoading, setAdditionalImageOneLoading] =
      useState<boolean>(false);
  const [additionalImageOne, setAdditionalImageOne] = useState<any>();
  const [additionalImageOneFileId, setAdditionalImageOneFileId] =
      useState<any>();
  const [isShowAdditionalImageTwo, setIsShowAdditionalImageTwo] =
      useState<boolean>(false);
  const [additionalImageTwoLoading, setAdditionalImageTwoLoading] =
      useState<boolean>(false);
  const [additionalImageTwo, setAdditionalImageTwo] = useState<any>();
  const [additionalImageTwoFileId, setAdditionalImageTwoFileId] =
      useState<any>();
  const [isShowAdditionalImageThree, setIsShowAdditionalImageThree] =
      useState<boolean>(false);
  const [additionalImageThreeLoading, setAdditionalImageThreeLoading] =
      useState<boolean>(false);
  const [additionalImageThree, setAdditionalImageThree] = useState<any>();
  const [additionalImageThreeFileId, setAdditionalImageThreeFileId] =
      useState<any>();
  const [isShowAdditionalImageFour, setIsShowAdditionalImageFour] =
      useState<boolean>(false);
  const [additionalImageFourLoading, setAdditionalImageFourLoading] =
      useState<boolean>(false);
  const [additionalImageFour, setAdditionalImageFour] = useState<any>();
  const [additionalImageFourFileId, setAdditionalImageFourFileId] =
      useState<any>();

  useEffect(() => {
    // if(!(selectRequest && selectRequest.uid)) {
    //   navigate('/guidelin');
    // }

    if (!isEdit) {
      setIsShowTermModal(!isShowTermModal);
    }

    if (isEdit) {
      setFrontImage(article.front_image || " ");
      setBackImage(article.back_image || " ");
      setAdditionalImageOne(article.additional_image_1 || " ");
      setAdditionalImageTwo(article.additional_image_2 || " ");
      setAdditionalImageThree(article.additional_image_3 || " ");
      setAdditionalImageFour(article.additional_image_4 || " ");
    }

    // setIsShowfrontImage(true);
    // setIsShowbackImage(true);
    // setIsShowAdditionalImageOne(true);
    // setIsShowAdditionalImageTwo(true);
    // setIsShowAdditionalImageThree(true);
    // setIsShowAdditionalImageFour(true);

    if (isEdit) {
      article?.additional_infos.forEach((data: any) => {
        if (data === "change_front_image") setIsShowfrontImage(true);
        else if (data === "change_back_image") setIsShowbackImage(true);
        else if (data === "change_additional_image_1")
          setIsShowAdditionalImageOne(true);
        else if (data === "change_additional_image_2")
          setIsShowAdditionalImageTwo(true);
        else if (data === "change_additional_image_3")
          setIsShowAdditionalImageThree(true);
        else if (data === "change_additional_image_4")
          setIsShowAdditionalImageFour(true);
      });
    } else {
      setIsShowfrontImage(true);
      setIsShowbackImage(true);
      setIsShowAdditionalImageOne(true);
      setIsShowAdditionalImageTwo(true);
      setIsShowAdditionalImageThree(true);
      setIsShowAdditionalImageFour(true);
    }

    // if(getLengthCategory(selectRequest.major_category)){
    //   setIsShowHeightWidth(true);
    // } else setIsShowHeightWidth(false);
  }, []);

  // Start events handler
  const onUploadImgHandler = async (file: any, fieldName: string) => {
    if (fieldName === "frontImage") setFrontImgLoading(true);
    else if (fieldName === "backImage") setBackImgLoading(true);
    else if (fieldName === "additionalImageOne")
      setAdditionalImageOneLoading(true);
    else if (fieldName === "additionalImageTwo")
      setAdditionalImageTwoLoading(true);
    else if (fieldName === "additionalImageThree")
      setAdditionalImageThreeLoading(true);
    else if (fieldName === "additionalImageFour")
      setAdditionalImageFourLoading(true);

    try {
      // Start file upload
      const blob = file.slice(0, file.size, "image/jpeg");
      const newFile = new File([blob], file.name, { type: "image/jpeg" });
      // Build the form data - You can add other input values to this i.e descriptions, make sure img is appended last
      const formData = new FormData();
      formData.append("file", newFile);
      formData.append("requestUid", selectRequest?.requestUid);
      if (user?.id) formData.append("userId", user.id);
      formData.append("articleUid", selectRequest?.uid);
      const { data } = await post(FILE_API.create(), formData, {
        isFile: true,
      });

      if (data) {
        if (fieldName === "frontImage") {
          setFrontImage(data.publicUrl);
          setFrontImageFileId(data.id);
        } else if (fieldName === "backImage") {
          setBackImage(data.publicUrl);
          setBackImageFileId(data.id);
        } else if (fieldName === "additionalImageOne") {
          setAdditionalImageOne(data.publicUrl);
          setAdditionalImageOneFileId(data.id);
        } else if (fieldName === "additionalImageTwo") {
          setAdditionalImageTwo(data.publicUrl);
          setAdditionalImageTwoFileId(data.id);
        } else if (fieldName === "additionalImageThree") {
          setAdditionalImageThree(data.publicUrl);
          setAdditionalImageThreeFileId(data.id);
        } else if (fieldName === "additionalImageFour") {
          setAdditionalImageFour(data.publicUrl);
          setAdditionalImageFourFileId(data.id);
        }

        setTimeout(() => {
          if (fieldName === "frontImage") setFrontImgLoading(false);
          else if (fieldName === "backImage") setBackImgLoading(false);
          else if (fieldName === "additionalImageOne")
            setAdditionalImageOneLoading(false);
          else if (fieldName === "additionalImageTwo")
            setAdditionalImageTwoLoading(false);
          else if (fieldName === "additionalImageThree")
            setAdditionalImageThreeLoading(false);
          else if (fieldName === "additionalImageFour")
            setAdditionalImageFourLoading(false);
        }, 1000);
      }
    } catch (error) {
      if (fieldName === "frontImage") setFrontImgLoading(false);
      else if (fieldName === "backImage") setBackImgLoading(false);
      else if (fieldName === "additionalImageOne")
        setAdditionalImageOneLoading(false);
      else if (fieldName === "additionalImageTwo")
        setAdditionalImageTwoLoading(false);
      else if (fieldName === "additionalImageThree")
        setAdditionalImageThreeLoading(false);
      else if (fieldName === "additionalImageFour")
        setAdditionalImageFourLoading(false);
    }
  };

  const isUploadedRequidPhoto = () => {
    return !(frontImage && backImage);
  };

  const getLengthCategory = (category: string) => {
    return ["men-bags", "women-bags"].indexOf(category) !== -1;
  };

  const onRemoveImgHandler = async (fileId: any, fieldName: string) => {
    try {
      const { status } = await del(FILE_API.delete(fileId));
      if (status) {
        if (fieldName === "frontImage") {
          setFrontImage(null);
          setFrontImageFileId(null);
        } else if (fieldName === "backImage") {
          setBackImage(null);
          setBackImageFileId(null);
        } else if (fieldName === "additionalImageOne") {
          setAdditionalImageOne(null);
          setAdditionalImageOneFileId(null);
        } else if (fieldName === "additionalImageTwo") {
          setAdditionalImageTwo(null);
          setAdditionalImageTwoFileId(null);
        } else if (fieldName === "additionalImageThree") {
          setAdditionalImageThree(null);
          setAdditionalImageThreeFileId(null);
        } else if (fieldName === "additionalImageFour") {
          setAdditionalImageFour(null);
          setAdditionalImageFourFileId(null);
        }
      }
    } catch (error) {
      console.log("error:", error);
    }
  };

  const onSubmitPhotoHandler = async () => {
    const updatedArticle = {
      ...article,
      front_image: frontImage,
      back_image: backImage,
      frontImageFileId,
      backImageFileId,
      width,
      height,
      additional_image_1: additionalImageOne,
      additional_image_2: additionalImageTwo,
      additional_image_3: additionalImageThree,
      additional_image_4: additionalImageFour,
      additionalImageOneFileId,
      additionalImageTwoFileId,
      additionalImageThreeFileId,
      additionalImageFourFileId,
    };

    const imgData = {
      front_image: frontImage,
      back_image: backImage,
      additional_image_1: additionalImageOne,
      additional_image_2: additionalImageTwo,
      additional_image_3: additionalImageThree,
      additional_image_4: additionalImageFour,
    };

    try {
      // update_addiona_images
      const response = await putBnsApi(
          REQUEST_BNS_API.additional_images(state?.article?.article_code),
          imgData,
          { isArray: true }
      );
      if (response.status === "UPDATED") {
        toast.success("Image update Successfully!", toastValues);
        // update_addiona_images_local_server
        put(
            ARTICLE_API.update_articles_images(state?.article?.article_code),
            imgData
        );
      } else {
        toast.error("Some things went wrong!", toastValues);
      }
    } catch (error) {
      toast.error("Some things went wrong!", toastValues);
    }

    navigate(fromPathname, { state: updatedArticle });
  };

  const onChangeInputFieldHandler = (e: any, fieldName: string) => {
    const fieldValue = e.currentTarget.value;

    if (fieldValue <= 0 || fieldValue > 100) {
      toast.error("Sorry! Please, set within 1 to 100", toastValues);
    } else {
      if (fieldName === "height") setHeight(fieldValue);
      else if (fieldName === "width") setWidth(fieldValue);
    }
  };
  // End events handler

  return (
      <>
        <Container fluid className="background-white container-min-height">
          <Row>
            <Col lg={3} md={3} xs={12}>
              {" "}
            </Col>
            <Col lg={6} md={6} xs={12}>
              <Card.Title className="page-title">
                {t("image_upload.title_add_photos")}
              </Card.Title>
              <Card.Subtitle className="text-center">
                {t("image_upload.image_upload_page_subtitle")}
              </Card.Subtitle>

              {/* Start product category */}
              <Row className="my-4 d-flex justify-content-center">
                {isShowfrontImage ? (
                    <Col lg={3} md={4} xs={6} className="mb-4">
                      <div className="add-photo-box">
                        {frontImgLoading ? (
                            <ImageUploadLoader height={130} width={130} />
                        ) : (
                            <img
                                className="selected-img"
                                src={frontImage || photoPlaceholderFront}
                            />
                        )}
                        <div className="photo-box-title">
                          {" "}
                          {t("image_upload.img_front")}{" "}
                        </div>
                        <PhotoUploader
                            elId="front-image"
                            isImageLoaded={frontImage}
                            selectedImage={(file: any) =>
                                onUploadImgHandler(file, "frontImage")
                            }
                            onRotted={() => console.log("rotted trigger")}
                            onReset={() =>
                                onRemoveImgHandler(frontImageFileId, "frontImage")
                            }
                        />
                      </div>
                    </Col>
                ) : null}

                {isShowbackImage ? (
                    <Col lg={3} md={4} xs={6} className="mb-4">
                      <div className="add-photo-box">
                        {backImgLoading ? (
                            <ImageUploadLoader height={130} width={130} />
                        ) : (
                            <img
                                className="selected-img"
                                src={backImage || photoPlaceholderBack}
                            />
                        )}
                        <div className="photo-box-title">
                          {" "}
                          {t("image_upload.img_back")}{" "}
                        </div>
                        <PhotoUploader
                            elId="back-image"
                            isImageLoaded={backImage}
                            selectedImage={(file: any) =>
                                onUploadImgHandler(file, "backImage")
                            }
                            onRotted={() => console.log("rotted trigger")}
                            onReset={() =>
                                onRemoveImgHandler(backImageFileId, "backImage")
                            }
                        />
                      </div>
                    </Col>
                ) : null}

                {isShowAdditionalImageOne ? (
                    <Col lg={3} md={4} xs={6} className="mb-4">
                      <div className="add-photo-box">
                        {additionalImageOneLoading ? (
                            <ImageUploadLoader height={130} width={130} />
                        ) : (
                            <img
                                className="selected-img"
                                src={additionalImageOne || photoPlaceholder}
                            />
                        )}
                        <div className="photo-box-title">
                          {" "}
                          {t("image_upload.add_photo")}{" "}
                        </div>
                        <PhotoUploader
                            elId="additionalImageOne"
                            isImageLoaded={additionalImageOne}
                            selectedImage={(file: any) =>
                                onUploadImgHandler(file, "additionalImageOne")
                            }
                            onRotted={() => console.log("rotted trigger")}
                            onReset={() =>
                                onRemoveImgHandler(
                                    additionalImageOneFileId,
                                    "additionalImageOne"
                                )
                            }
                        />
                      </div>
                    </Col>
                ) : null}

                {isShowAdditionalImageTwo ? (
                    <Col lg={3} md={4} xs={6} className="mb-4">
                      <div className="add-photo-box">
                        {additionalImageTwoLoading ? (
                            <ImageUploadLoader height={130} width={130} />
                        ) : (
                            <img
                                className="selected-img"
                                src={additionalImageTwo || photoPlaceholder}
                            />
                        )}
                        <div className="photo-box-title">
                          {" "}
                          {t("image_upload.add_photo")}{" "}
                        </div>
                        <PhotoUploader
                            elId="additionalImageTwo"
                            isImageLoaded={additionalImageTwo}
                            selectedImage={(file: any) =>
                                onUploadImgHandler(file, "additionalImageTwo")
                            }
                            onRotted={() => console.log("rotted trigger")}
                            onReset={() =>
                                onRemoveImgHandler(
                                    additionalImageTwoFileId,
                                    "additionalImageTwo"
                                )
                            }
                        />
                      </div>
                    </Col>
                ) : null}

                {isShowAdditionalImageThree ? (
                    <Col lg={3} md={4} xs={6} className="mb-4">
                      <div className="add-photo-box">
                        {additionalImageThreeLoading ? (
                            <ImageUploadLoader height={130} width={130} />
                        ) : (
                            <img
                                className="selected-img"
                                src={additionalImageThree || photoPlaceholder}
                            />
                        )}
                        <div className="photo-box-title">
                          {" "}
                          {t("image_upload.add_photo")}{" "}
                        </div>
                        <PhotoUploader
                            elId="additionalImageThree"
                            isImageLoaded={additionalImageThree}
                            selectedImage={(file: any) =>
                                onUploadImgHandler(file, "additionalImageThree")
                            }
                            onRotted={() => console.log("rotted trigger")}
                            onReset={() =>
                                onRemoveImgHandler(
                                    additionalImageThreeFileId,
                                    "additionalImageThree"
                                )
                            }
                        />
                      </div>
                    </Col>
                ) : null}

                {isShowAdditionalImageFour ? (
                    <Col lg={3} md={4} xs={6} className="mb-4">
                      <div className="add-photo-box">
                        {additionalImageFourLoading ? (
                            <ImageUploadLoader height={130} width={130} />
                        ) : (
                            <img
                                className="selected-img"
                                src={additionalImageFour || photoPlaceholder}
                            />
                        )}
                        <div className="photo-box-title">
                          {" "}
                          {t("image_upload.add_photo")}{" "}
                        </div>
                        <PhotoUploader
                            elId="additionalImageFour"
                            isImageLoaded={additionalImageFour}
                            selectedImage={(file: any) =>
                                onUploadImgHandler(file, "additionalImageFour")
                            }
                            onRotted={() => console.log("rotted trigger")}
                            onReset={() =>
                                onRemoveImgHandler(
                                    additionalImageFourFileId,
                                    "additionalImageFour"
                                )
                            }
                        />
                      </div>
                    </Col>
                ) : null}
              </Row>
              {/* End Images      */}

              {/* Start height/width section  */}

              {isShowHeightWidth ? (
                  <>
                    <div className="text-center my-4">
                      <h5>{t("additional_information_required")}</h5>
                    </div>
                    <Row className="my-4">
                      <Col lg={6} md={6} xs={6}>
                        <InputGroup className="mb-3">
                          <Form.Control
                              type="number"
                              min="1"
                              max="100"
                              value={width}
                              placeholder={t("bag_width") || ""}
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                              onChange={(e: any) =>
                                  onChangeInputFieldHandler(e, "width")
                              }
                          />
                          <InputGroup.Text id="photoWidth">cm</InputGroup.Text>
                        </InputGroup>
                      </Col>
                      <Col lg={6} md={6} xs={6}>
                        <InputGroup className="mb-3">
                          <Form.Control
                              type="number"
                              min="1"
                              max="100"
                              value={height}
                              placeholder={t("bag_height") || ""}
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon3"
                              onChange={(e: any) =>
                                  onChangeInputFieldHandler(e, "height")
                              }
                          />
                          <InputGroup.Text id="photoHeight">cm</InputGroup.Text>
                        </InputGroup>
                      </Col>
                    </Row>
                  </>
              ) : (
                  <></>
              )}
              {/* End height/width section  */}

              {/* <p className="text-center py-3 pb-5">
                {t('image_upload.img_message')}
              </p> */}
              <div className="how-to-form-footer pt-3 pb-5 px-3 d-flex justify-content-between">
                <Button
                    onClick={() => navigate(-1)}
                    className="round-btn"
                    variant="dark"
                    size="sm"
                >
                  {t("return")}
                </Button>
                <Button
                    disabled={isUploadedRequidPhoto()}
                    onClick={() => onSubmitPhotoHandler()}
                    className="round-btn"
                    variant="dark"
                    size="sm"
                >
                  {t("continue")}
                </Button>
              </div>
            </Col>
            <Col lg={3} md={3} xs={12}>
              {" "}
            </Col>
          </Row>
        </Container>

        {/* Start load components  */}
      </>
  );
};

export default AdditionalInfos;
