import { Container, Row, Col } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

// Start import components
import RequestCard from "components/common/RequestCard";
import RequestReviewCard from "components/private/RequestReviewCard";

// Start import images
import { product1, arrow } from "assets/image/image";

const LandingPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { authUser } = useSelector(({ Login }: { Login: any }) => Login);
  const [isAuth, setIsAuth] = useState<boolean>(true);

  useEffect(() => {
    setIsAuth(!!authUser);
  });

  const cardClickHandler = () => {
    navigate("/guidelin");
  };

  const myRequest = () => {
    if(isAuth) navigate("/my-requests");
    else navigate("/login", {state: {...location, pathname: '/my-requests'}});
  };

  return (
      <Container fluid>
        <div className='d-flex justify-content-center background-white container-min-height'>
          <Row className="py-5">
            <Col md={6} xs={12}>
              <RequestCard onClick={() => cardClickHandler()} image={product1} arrowIcon={arrow} />
            </Col>
            <Col md={6} xs={12}>
              <RequestReviewCard onClick={() => myRequest()} />
            </Col>
          </Row>
        </div>
      </Container>
  );
};

export default LandingPage;
