export const ARTICLE_API = {
  fetch_my_requests: (withoutarr:any) => `/sendin/api/v1/purchase_requests?ref_nos= [${withoutarr}]`,
  purchase_requests: () => `/sendin/api/v1/purchase_requests`,
  create: () => `/api/article`,
  create_all: () => `/api/bulkArticles`,
  fetch_article_ref: () => `/api/all-ref`,
  fetch_request_by_id: (referenceNumber:string) => `/sendin/api/v1/purchase_requests/${referenceNumber}`,
  update_articles: (article_code:any) => `/api/updateArticl/${article_code}`,
  update_articles_images: (article_code:any) => `/api/updateArticlImages/${article_code}`,
};
