import _ from 'lodash';
import Swal from 'sweetalert2';

// Start import images
import {
    parentCategoryMale,
    parentCategoryFemale,
    categoryShoe,
    categoryAccessory,
    categoryBag,
    categorySwimwear,
    categoryTop,
    categoryBottom,
    productSubTypeJacket,
    productSubTypeSuit,
    productSubTypeDress,
    commonCategory,
    womenSuits,
    menSwimer,
    menBag,
    menShoe,
    menJacket,
    menAccessories,
    womenBottom,
    womenTop,
} from 'assets/image/image';

/**
 * Get Capitalized word
 * @param word
 * @returns {String}
 */
export const capitalize = (word:any) => {
    const lower = word.toLowerCase();
    return word.charAt(0).toUpperCase() + lower.slice(1);
};

/**
 * @param number
 * @returns string
 */
export const getRandomId = (length:number) => {
    return Math.random().toString(36).slice(2, length);
}

/**
 * Get Dropdown shape Array
 * @param array
 * @returns {Array}
 */
export const mapArrayToDropdown = (arr:any, label:any, value:any) => {
    const dropdown = arr.map((item:any) => ({ ...item, label: item[label], value: item[value] }));
    return dropdown;
};

/**
 * Get Dropdown shape Array
 * @param array
 * @returns {Array}
 */
export const mapKeyToDropdown = (arr:any) => {
    let list:any = [];
    for(let i = 0; i < arr.length; ++i){
        const obj:any = {};
        obj.value = arr[i];
        obj.label = arr[i];
        list.push(obj);
    }
    return list;
};

/**
 * Get Full name
 * @param firstName
 * @param middleName
 * @param lastName
 * @returns {String}
 */
export const getFullName = (firstName:string, middleName:any, lastName:any) => {
    return `${lastName}, ${firstName}`;
};

// /**
//  * Get Key from Value
//  * @param Object
//  * @param key
//  * @returns {String}
//  */
// export const getKeyfromValue = (object = {}, value:any) => {
//   return Object.keys(object).find((key) => object[key] === value);
// };

/**
 * Artificial Delay
 * @param milisecond as ms
 * @returns {Function}
 */
export const sleep = (ms:any) => new Promise((resolve) => setTimeout(resolve, ms));

// /**
//  * console log data
//  * @param data
//  * @param type
//  * @returns {Function}
//  */
// export const stringifyConsole = (data:any, type = consoleType.normal) => {
//   if (process.env.NODE_ENV === 'development') {
//     if (type === consoleType.normal) {
//       console.log(data);
//     } else {
//       console.log(JSON.stringify(data, null, 2));
//     }
//   }
// };

/**
 * check item exists
 * @param array
 * @param ui data
 * @returns {Function}
 */
export const checkItemExists = (data:any, value:any) => {
    const checkBox = data?.some((item:any) => item.name === value);
    if (checkBox === true) {
        return 'far fa-check-square';
    } else {
        return 'far fa-square';
    }
};
export const getClassNameForItem = (data:any, value:any, matchclass:any, unmatchedClass:any) => {
    const checkBox = data?.some((item:any) => item.name === value);
    if (checkBox === true) {
        return matchclass;
    } else {
        return unmatchedClass;
    }
};
export const getClassNameForYesNo = (data:any, value:any, yesClass:any, noClass:any) => {
    return data === value ? yesClass : noClass;
};
/**
 * check boolean for true
 * @param array
 * @param ui data
 * @returns {css}
 */
export const checkTrue = (data:boolean) => {
    if (data === true) {
        return 'me-1 fas fa-check-circle';
    } else {
        return 'me-1 far fa-circle';
    }
};

/**
 * check boolean for false
 * @param array
 * @param ui data
 * @returns {css}
 */
export const checkfalse = (data:boolean) => {
    if (data === true) {
        return 'me-1 far fa-circle';
    } else {
        return 'me-1 fas fa-check-circle';
    }
};

export const majorCategoriesImg = (name:any) => {
    switch (name) {
        case "men-suits":
            return productSubTypeSuit;
        case "men-accessories" :
            return menAccessories;
        case "men-jackets" :
            return menJacket
        case "men-tops" :
            return  categoryTop;
        case "men-bottoms" :
            return categoryBottom;
        case "men-shoes" :
            return menShoe
        case "men-bags" :
            return menBag
        case "men-underwear" :
            return  menSwimer
        case "women-suits" :
            return  womenSuits
        case "women-dresses" :
            return  productSubTypeDress
        case "women-accessories" :
            return  categoryAccessory
        case "women-jackets" :
            return  productSubTypeJacket
        case "women-tops" :
            return  womenTop
        case "women-bottoms" :
            return  womenBottom
        case "women-shoes" :
            return  categoryShoe
        case "women-bags" :
            return  categoryBag
        case "women-underwear" :
            return categorySwimwear
        case name :
            return commonCategory
    }
}

//Find index of an element in array/object with attribute
//Params: array, attr - attribute which is searched, value - matching value
export const findWithAttr = (array:any, attr:any, value:any) => {
    for (var i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
            return i;
        }
    }
    return -1;
};

/**
 * check boolean for false
 * @param array
 * @param ui data
 * @returns {css}
 */
export const mapObjectToDropdown = (obj:object) => {
    const arr = [];
    for (const [key, value] of Object.entries(obj)) {
        arr.push({
            label: _.startCase(key),
            value: value
        });
    }
    return arr;
};

/**
 * check boolean for false
 * @param array
 * @param ui data
 * @returns {css}
 */
export const actionConfirm = (config:any) => {
    return new Promise((resolve, reject) => {
        Swal.fire({
            title: config?.title || 'Are you sure?',
            text: config?.text || "You won't be able to revert this!",
            icon:  config?.icon || 'warning',
            showCancelButton: config?.isShowCancelBtn || true,
            confirmButtonColor: config?.confirmBtnColor || '#000000',
            cancelButtonColor: config?.cancelBtnColor || '#d33',
            confirmButtonText: config?.confirmButtonText || 'Yes, delete it!',
            cancelButtonText: config?.cancelButtonText || 'Cancel'
        }).then((result:any) => {
            resolve(result);
        })
    })
};

/**
 * check boolean for false
 * @param string
 * @returns {}
 */
export const getRequstStatus = (status:any) => {
    let statusText = "Not Defined.";
    let color = "req-state-picture";

    if(status === 'open') {
        statusText = "Open";
        color = "req-state-open";
    } else if(status === 'rejected') {
        statusText = "Rejected";
        color = "req-state-picture";
    } else if(status === "rejected" || status === "confirmed" || status === "waiting_for_customer" || status === "waiting_for_approval") {
        statusText = "In Progress";
        color = "req-state-inprogress";
    } else if(status === "waiting_for_customer") {
        statusText = "Waiting for Customer";
        color = "req-state-picture";
    } else if(status === "confirmed") {
        statusText = "Request available";
        color = "req-state-inprogress";
    }

    return { statusText, color };
};

export const getCategoryName = (mainCategories:any, article:any, fieldName:string) => {
    let mainCategoryName, majorCategoryName, minorCategoryName;
    const mainCatKey = article.main_category;
    const majorCatKey = article.major_category;
    const minorCatKey = article.minor_category;

    if(mainCategories.length) {
        mainCategories.forEach((mainCat:any) => {
            if(mainCatKey === mainCat.key) {
                mainCategoryName = mainCat.name;
                mainCat.major_categories.forEach((majorCat:any) => {
                    if(majorCatKey === majorCat.key) {
                        majorCategoryName = majorCat.name;
                        majorCat.minor_categories.forEach((minorCat:any) => {
                            if(minorCatKey === minorCat.key) {
                                minorCategoryName = minorCat.name;
                            }
                        })
                    }
                })
            }
        });
    }

    if(fieldName === 'minor_category') return minorCategoryName;
    else if(fieldName === 'major_category') return majorCategoryName;
    else if(fieldName === 'main_category') return mainCategoryName;
    else if(fieldName === 'all') return { mainCategoryName, majorCategoryName, minorCategoryName };
};

export const stepperStyleConfig = {
    activeBgColor: '#8E63FF',
    activeTextColor: '#fff',
    inactiveBgColor: '#eee',
    inactiveTextColor: '#333',
    completedBgColor: '#8E63FF',
    completedTextColor: '#fff',
    size: '2em',
    circleFontSize: '',
    labelFontSize: '0.875rem',
    borderRadius: '50%',
    fontWeight: 500
  };
  
export const stepperConnectorStyleConfig = {
    disabledColor: '#eee',
    completedColor: '#8E63FF',
    activeColor: '#8E63FF',
    size: 2,
    stepSize: '2em',
    style: 'solid'
  };
  
  