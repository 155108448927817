import { Container, Card, Button, Form, Col, Row, InputGroup} from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import ReactSelect from 'react-select';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

// Start import helpers
import { updateRequest } from "store/request/actions";
import { mapKeyToDropdown, mapArrayToDropdown, majorCategoriesImg } from 'helpers/common_helper';
import { toastValues } from 'common/toastValues';
import { del, put, getBnsApi, post } from 'helpers/api_helpers';
import { BRAND_API} from 'constants/apiEndPoints/bnsApi/brandEndpoints';
import { CATEGORY_API } from 'constants/apiEndPoints/bnsApi/categoriesEndpoints';
import {
    parentCategoryMale,
    parentCategoryFemale,
    photoPlaceholderFront,
    photoPlaceholderBack,
    photoPlaceholder,
} from '../assets/image/image';

// Start import components
import PhotoUploader from "../components/common/PhotoUploader";
import ImageUploadLoader from "components/common/ImageUploadLoader";
import { useSelector } from 'react-redux';
import { FILE_API } from 'constants/apiEndPoints/fileEndpoints';
import { CART_API } from 'constants/apiEndPoints/bnsApi/cartEndpoints';
import { useTranslation } from 'react-i18next';

const EditArticle = () => {
    let { id } = useParams();
    const { t } = useTranslation();
    const { authUser } = useSelector(({ Login }: { Login: any }) => Login);
    const { requests } = useSelector(({ Request }: { Request: any }) => Request);
    const selectedArticle = requests.find((data:any)=> data.articleUid === id);

    const navigate = useNavigate();
    const dispatch = useDispatch();
    //Brand init state
    const [articles, setArticles] = useState<any>(requests);
    const [article, setArticle] = useState<any>(selectedArticle || {});
    const [brands, setBrands] = useState<any>([]);
    const [brand, setBrand] = useState<any>({label: article?.brand, value: article?.brand});

    //Main category init state
    const [mainCategories, setMainCategories] = useState<any>([]);
    const [mainCategory, setMainCategory] = useState<any>(article.main_category);

    //Major category init state
    const [majorCategories, setMajorCategories] = useState<any>([]);
    const [majorCategory, setMajorCategory] = useState<any>({label: article.major_category, value: article.major_category});
    const [newmajorCategory, setNewMajorCategory] = useState<any>(article.major_category);

    //Minor category init state
    const [minorCategories, setMinorCategories] = useState<any>([]);
    const [minorCategory, setMinorCategory] = useState<any>({label: article.minor_category, value: article.minor_category});
    const [newminorCategory, setNewMinorCategory] = useState<any>(article.minor_category);

    const [frontImage, setFrontImage] = useState<any>(article.front_image);
    const [backImage, setBackImage] = useState<any>(article.back_image);
    const [frontImgLoading, setFrontImgLoading] = useState<boolean>(false);
    const [frontImageFileId, setFrontImageFileId] = useState<any>();
    const [backImgLoading, setBackImgLoading] = useState<boolean>(false);
    const [backImageFileId, setBackImageFileId] = useState<any>();
    const [isShowHeightWidth, setIsShowHeightWidth] = useState<boolean>(false);
    const [width, setWidth] = useState<any>(article.width);
    const [height, setHeight] = useState<any>(article.height);
    const [isCheckFormValidation, setIsCheckFormValidation] = useState<boolean>(false);
    const [isShowAdditionalImageOne, setIsShowAdditionalImageOne] = useState<boolean>(true);
    const [additionalImageOneLoading, setAdditionalImageOneLoading] = useState<boolean>(false);
    const [additionalImageOne, setAdditionalImageOne] = useState<any>(article?.additional_image_1);
    const [additionalImageOneFileId, setAdditionalImageOneFileId] = useState<any>();
    const [isShowAdditionalImageTwo, setIsShowAdditionalImageTwo] = useState<boolean>(true);
    const [additionalImageTwoLoading, setAdditionalImageTwoLoading] = useState<boolean>(false);
    const [additionalImageTwo, setAdditionalImageTwo] = useState<any>(article?.additional_image_2);
    const [additionalImageTwoFileId, setAdditionalImageTwoFileId] = useState<any>();
    const [isShowAdditionalImageThree, setIsShowAdditionalImageThree] = useState<boolean>(true);
    const [additionalImageThreeLoading, setAdditionalImageThreeLoading] = useState<boolean>(false);
    const [additionalImageThree, setAdditionalImageThree] = useState<any>(article?.additional_image_3);
    const [additionalImageThreeFileId, setAdditionalImageThreeFileId] = useState<any>();
    const [isShowAdditionalImageFour, setIsShowAdditionalImageFour] = useState<boolean>(true);
    const [additionalImageFourLoading, setAdditionalImageFourLoading] = useState<boolean>(false);
    const [additionalImageFour, setAdditionalImageFour] = useState<any>(article?.additional_image_4);
    const [additionalImageFourFileId, setAdditionalImageFourFileId] = useState<any>();
  

    // Start lifecycle methods
    useEffect(() => {
        if(!selectedArticle && authUser) {
            navigate("/your-article");
            return;
        } else if(!selectedArticle && !authUser) {
            navigate("/categories");
            return;
        }
    }, [authUser]);

    useEffect(() => {
        // setArticle(selectedArticle);
        // fetch all brands'
        fetchBrands();
        // fetch al Category
        fetchCategory();
    }, []);

    useEffect(() => {
        //Set edit img file id
        if(article && article.frontImageFileId) setFrontImageFileId(article.frontImageFileId);
        if(article && article.backImageFileId) setBackImageFileId(article.backImageFileId);
        if(article && article.additionalImageOneFileId) setAdditionalImageOneFileId(article.additionalImageOneFileId);
        if(article && article.additionalImageTwoFileId) setAdditionalImageTwoFileId(article.additionalImageTwoFileId);
        if(article && article.additionalImageThreeFileId) setAdditionalImageThreeFileId(article.additionalImageThreeFileId);
        if(article && article.additionalImageFourFileId) setAdditionalImageFourFileId(article.additionalImageFourFileId);

        if(article && article.width) setWidth(article.width);
        if(article && article.height) setHeight(article.height);

        if(getLengthCategory(article.major_category)){
            setIsShowHeightWidth(true);
        } else setIsShowHeightWidth(false);
      
    }, [article]);

    useEffect(() => {
        initMajorCategories(mainCategories);
        mainCategories.forEach((mainCat:any) => {
            if(mainCat.key === mainCategory) {
                const majorCatList = mainCat.major_categories || [];
                const majorCat = majorCatList.find((item:any)=> item.key === majorCategory.value);
                setMajorCategory({...majorCategory, label: majorCat?.name});

                const minorCatList = majorCat?.minor_categories || [];
                const minorCat = minorCatList.find((item:any)=> item.key === minorCategory.value);
                setMinorCategory({...minorCategory, label: minorCat?.name});
            }
        });
    }, [mainCategories]);

    useEffect(() => {
        initMinorCategories(minorCategory);
    }, [majorCategories]);

    useEffect(() => {
        initMajorCategories(mainCategories);
    }, [mainCategory]);

    useEffect(() => {
        initMinorCategories(minorCategory);
    }, [majorCategory]);
    // End lifecycle methods

    const initMajorCategories = (item:object) => {
        const majorCategories = mainCategories.find((mainCat:any) => mainCat.key === mainCategory );
        const majorCatList = majorCategories?.major_categories || [];
        if(majorCatList.length) {
            const majorCategoriesDropdown = mapArrayToDropdown(majorCatList, 'name', 'key');
            setMajorCategories(majorCategoriesDropdown);
        }
    };

    const initMinorCategories = (item:object) => {
        const minorCategories = majorCategories.find((majorCat:any) => majorCat.key === majorCategory.value );
        const minorCatList = minorCategories?.minor_categories || [];
        if(minorCatList.length) {
            const minorCategoriesDropdown = mapArrayToDropdown(minorCatList, 'name', 'key');
            setMinorCategories(minorCategoriesDropdown);
        }
    };

    const fetchBrands = async () => {
        try {
            const { data } = await getBnsApi(BRAND_API.fetch_all());
            const brandsDropdown = mapKeyToDropdown(data.values);
            setBrands(brandsDropdown);
        } catch (error:any) {
            toast.error(error.message, toastValues);
        }
    };

    const fetchCategory= async () => {
        try {
            const { data } = await getBnsApi(CATEGORY_API.fetch_all());
            setMainCategories(data.main_categories);
        } catch (error:any) {
            toast.error(error.message, toastValues);
        }
    };

    // Start events handler
    const onSearchSelectChangeHandler = (item:any) => {
        setBrand(item)
    }

    // majorCategoryList
    const onChangeMainCategoryHandler = (itemList:any, key:any) => {
        setMajorCategories(itemList);
        setMainCategory(key);
        setMinorCategories([])
        setNewMajorCategory('')
        setNewMinorCategory('')
        // setMajorCategory({});
        // setMinorCategory({});
    }

    const onChangeMajorCategoryHandler = (itemList:any, key:any) => {
        // setMinorCategories(itemList)
        setNewMajorCategory(key)
        setNewMinorCategory('')
        // setMajorCategory(type);
        // setMajorCategory(type)
        // setMinorCategory({});

        if(getLengthCategory(key)) setIsShowHeightWidth(true);
        else setIsShowHeightWidth(false);
    }

    const getLengthCategory = (category:string) => {
        return (['men-bags', 'women-bags'].indexOf(category) !== -1)
    }

    const minorCategoryHandler = (key:any) => {
        setNewMinorCategory(key);
        // setMinorCategory(key);
    }

    // Start img uplod events handler
    const onUploadImgHandler = async (file:any, fieldName:string) => {

        if(fieldName === 'frontImage') setFrontImgLoading(true);
        else if(fieldName === 'backImage') setBackImgLoading(true);
        else if(fieldName === 'additionalImageOne') setAdditionalImageOneLoading(true);
        else if(fieldName === 'additionalImageTwo') setAdditionalImageTwoLoading(true);
        else if(fieldName === 'additionalImageThree') setAdditionalImageThreeLoading(true);
        else if(fieldName === 'additionalImageFour') setAdditionalImageFourLoading(true);

        try {
            // Start file upload
            const blob = file.slice(0, file.size, "image/jpeg");
            const newFile = new File([blob], file.name, { type: "image/jpeg" });

            // Build the form data - You can add other input values to this i.e descriptions, make sure img is appended last
            const formData = new FormData();
            formData.append("file", newFile);

            formData.append("requestUid", article?.requestUid);
            if(article?.userId) formData.append("userId", article?.userId);
            formData.append("articleUid", article?.uid);
            const { data } = await post(FILE_API.create(), formData, {isFile: true});

            if(data) {

                if(fieldName === 'frontImage') {
                    setFrontImage(data.publicUrl);
                    setFrontImageFileId(data.id);
                } else if(fieldName === 'backImage') {
                    setBackImage(data.publicUrl);
                    setBackImageFileId(data.id);
                } else if(fieldName === 'additionalImageOne') {
                    setAdditionalImageOne(data.publicUrl);
                    setAdditionalImageOneFileId(data.id);
                  } else if(fieldName === 'additionalImageTwo') {
                    setAdditionalImageTwo(data.publicUrl);
                    setAdditionalImageTwoFileId(data.id);
                  } else if(fieldName === 'additionalImageThree') {
                    setAdditionalImageThree(data.publicUrl);
                    setAdditionalImageThreeFileId(data.id);
                  } else if(fieldName === 'additionalImageFour') {
                    setAdditionalImageFour(data.publicUrl);
                    setAdditionalImageFourFileId(data.id);
                  };

                setTimeout(()=>{
                    if(fieldName === 'frontImage') setFrontImgLoading(false);
                    else if(fieldName === 'backImage') setBackImgLoading(false);
                    else if(fieldName === 'additionalImageOne') setAdditionalImageOneLoading(false);
                    else if(fieldName === 'additionalImageTwo') setAdditionalImageTwoLoading(false);
                    else if(fieldName === 'additionalImageThree') setAdditionalImageThreeLoading(false);
                    else if(fieldName === 'additionalImageFour') setAdditionalImageFourLoading(false);
                }, 1000);
            }
        } catch (error) {

            if(fieldName === 'frontImage') setFrontImgLoading(false);
            else if(fieldName === 'backImage') setBackImgLoading(false);
            else if(fieldName === 'additionalImageOne') setAdditionalImageOneLoading(false);
            else if(fieldName === 'additionalImageTwo') setAdditionalImageTwoLoading(false);
            else if(fieldName === 'additionalImageThree') setAdditionalImageThreeLoading(false);
            else if(fieldName === 'additionalImageFour') setAdditionalImageFourLoading(false);
        }

    };

    const isUploadedRequidPhoto = () => {
        return !(frontImage && backImage);
    };

    const isInavalidForm = () => {
        return (!majorCategory?.value || !(frontImage && backImage));
    };

    const onRemoveImgHandler = async (fileId:any, fieldName:string) => {
        try {
            const { status } = await del(FILE_API.delete(fileId));
            if(status) {
                if(fieldName === 'frontImage') {
                    setFrontImage(null);
                    setFrontImageFileId(null);

                } else if(fieldName === 'backImage') {
                    setBackImage(null);
                    setBackImageFileId(null);
                } else if(fieldName === 'additionalImageOne') {
                    setAdditionalImageOne(null);
                    setAdditionalImageOneFileId(null);
                  } else if(fieldName === 'additionalImageTwo') {
                    setAdditionalImageTwo(null);
                    setAdditionalImageTwoFileId(null);
                  } else if(fieldName === 'additionalImageThree') {
                    setAdditionalImageThree(null);
                    setAdditionalImageThreeFileId(null);
                  } else if(fieldName === 'additionalImageFour') {
                    setAdditionalImageFour(null);
                    setAdditionalImageFourFileId(null);
                  };
            }
        } catch (error) {
            console.log('error:', error);
        }
    };
    // end img uplod events handler

    const onSubmitBrandAndType = async () => {
        if(!isInavalidForm()){
            if(!newmajorCategory) {
                toast.error(`Please Select Major Category!`, toastValues);
                return;
              } else if(!mainCategory){
                toast.error(`Please select MainCategory!`, toastValues);
                return;
              } else if(!(frontImage && backImage)){
                toast.error(`Please select Image!`, toastValues);
                return;
              } 
              
            //   else if(!newminorCategory){
            //     toast.error(`Please select MinorCategory!`, toastValues);
            //     return;
            //   }      
    
            // const id = getRandomId(12);
    
            const editedArticle =  {
                uid:id,
                requestUid: article.requestUid,
                name: `${brand.value} ${newmajorCategory || newminorCategory}`,
                main_category: mainCategory,
                major_category: newmajorCategory,
                // minor_category: newminorCategory,
                brand: brand.value,
                status: "Pending",
                front_image: frontImage,
                back_image: backImage,
                height: isShowHeightWidth? height:'',
                width: isShowHeightWidth? width:'',
                additional_image_1: additionalImageOne,
                additional_image_2: additionalImageTwo,
                additional_image_3: additionalImageThree,
                additional_image_4: additionalImageFour,
                additionalImageOneFileId,
                additionalImageTwoFileId,
                additionalImageThreeFileId,
                additionalImageFourFileId
            };
            
            const articlesList:any = [];
            articles.forEach((data:any) => {
                if(data?.uid === editedArticle?.uid) {
                    data = {...data, ...editedArticle};
                }
                articlesList.push(data);
            });
    
            dispatch(updateRequest(articlesList));
    
            try{
                await put(CART_API.update_one(), articlesList[0]);
            } catch(err){
                console.log(err);
            }
            navigate('/your-article');
        } else {
            if(isCheckFormValidation) setIsCheckFormValidation(false);
            setTimeout(()=>{
                setIsCheckFormValidation(true);
            })
        }
    };

    const onChangeInputFieldHandler = (e:any, fieldName:string) =>{
        const fieldValue = e.currentTarget.value;

        if(fieldValue <= 0 || fieldValue > 100 ) {
            toast.error("Sorry! Please, set within 1 to 100", toastValues);
        } else {
            if(fieldName === 'height') setHeight(fieldValue);
            else if(fieldName === 'width') setWidth(fieldValue);
        }
    }
    // End events handler

    return (
        <>
            <Container fluid className="background-white">
                <Card.Title className="page-title">{t('select_category_brand_edit_page')} </Card.Title>
                <Row>
                    <Col lg={3} md={3} xs={12}> </Col>
                    <Col lg={6} md={6} xs={12}>
                        <div className="form-field brand-search-field">
                            <ReactSelect
                                options={brands}
                                value={brand}
                                onChange={(onSearchSelectChangeHandler)} />
                        </div>
                    </Col>
                    <Col lg={3} md={3} xs={12}> </Col>
                </Row>

                {/* Start product category */}
                <Row className="my-5 d-flex justify-content-center">
                    {
                        mainCategories.map((genderCategory:any, index:number)=> {
                            return (
                                <Col key={`${genderCategory.key}_${index}`} className="text-center" lg={3} md={3} xs={6}>

                                    <Form.Check
                                        className="category-card"
                                        type="radio"
                                        id={`${genderCategory.key}-category`} >

                                        <Form.Check.Input
                                            type="radio"
                                            className="bns-checkbox bns-card round"
                                            name="mainCategory"
                                            checked={genderCategory.key === mainCategory}
                                            onChange={(e) => onChangeMainCategoryHandler(genderCategory.major_categories, genderCategory.key)} />

                                        <Form.Check.Label className="cursor-pointer">
                                            <Card className="category-card-item">
                                                <div className="category-card-item-content">
                                                    <img src={genderCategory.key === 'men' ? parentCategoryMale : parentCategoryFemale} title={genderCategory.name}  />
                                                    <p>{genderCategory.name}</p>
                                                </div>
                                            </Card>
                                        </Form.Check.Label>

                                    </Form.Check>
                                </Col>
                            )
                        })
                    }
                </Row>

                <h3 className="text-center">{t('product_type')}</h3>
                <Row className="my-5 d-flex justify-content-center">
                    {
                        majorCategories.map((majorCategory:any, index:number)=> {
                            return (
                                <Col key={`${majorCategory.key}_${index}`} className="mb-4" lg={2} md={3} xs={6}>
                                    <Form.Check className="category-card" type="radio" id={`${majorCategory.key}-major-category`}>
                                        <Form.Check.Input type="radio" 
                                                        name="majorCategory"
                                                        className="bns-checkbox bns-card round"
                                                        checked={majorCategory.key === newmajorCategory}
                                                        onChange={(e) => onChangeMajorCategoryHandler(majorCategory.minor_categories, majorCategory.key)}
                                        />
                                        <Form.Check.Label>
                                            <Card className="category-card-item">
                                                <div className="category-card-item-content">
                                                    <img src={majorCategoriesImg(majorCategory.key)} title={majorCategory.label}  />
                                                    <p>{majorCategory.name}</p>
                                                </div>
                                            </Card>
                                        </Form.Check.Label>
                                    </Form.Check>
                                </Col>
                            )
                        })
                    }
                </Row>

                {/* Start minor category  */}
                {/* <h3 className="text-center">Minor Cetagory</h3>
                <Row className="my-5 d-flex justify-content-center">
                    {
                        minorCategories.map((item:any, index:number)=> {
                            return (
                                <Col key={`${item.name}_${index}`} lg={3} md={3} xs={6} className="mb-3">
                                    <Form.Check className="category-card" type="radio" id={`${item.name}-minor-category`}>
                                        <Form.Check.Input type="radio" name="minorCategory"
                                                          checked={item.key === newminorCategory}
                                                          onChange={(e) => minorCategoryHandler(item.key)}
                                        />
                                        <Form.Check.Label>
                                            <Card className="category-card-item">
                                                <div className="category-card-item-content">
                                                    <img src={majorCategoriesImg(item.name)} title={item.name} />
                                                    <p>{item.name}</p>
                                                </div>
                                            </Card>
                                        </Form.Check.Label>
                                    </Form.Check>
                                </Col>
                            )
                        })
                    }
                </Row> */}
                {/* End minor category  */}

                {/* Start photos section  */}
               <h3 className="text-center">Potos</h3>
                <Row className="my-4 d-flex justify-content-center">
                    <Col lg={3} md={3} xs={6}>
                        <div className={`add-photo-box ${isCheckFormValidation && !frontImage?'invalid':''}`}>
                            {
                                frontImgLoading? <ImageUploadLoader height={130} width={130} />
                                    :<img
                                        className="selected-img"
                                        src={frontImage || photoPlaceholderFront}
                                    />
                            }
                            <div className="photo-box-title"> {t('image_upload.img_front')} </div>
                            <PhotoUploader
                                elId="front-image"
                                isImageLoaded={frontImage}
                                selectedImage={(file: any) => onUploadImgHandler(file, 'frontImage')}
                                onRotted={() => console.log("rotted trigger")}
                                onReset={() => onRemoveImgHandler(frontImageFileId, 'frontImage')}
                            />
                            <small className="invalid-text">{t('form.invalid_message')}</small>
                        </div>
                    </Col>
                    <Col lg={3} md={3} xs={6}>
                        <div className={`add-photo-box ${isCheckFormValidation && !backImage?'invalid':''}`}>
                            {
                                backImgLoading? <ImageUploadLoader height={130} width={130} />
                                    : <img
                                        className="selected-img"
                                        src={backImage || photoPlaceholderBack}
                                    />
                            }
                            <div className="photo-box-title"> {t('image_upload.img_back')} </div>
                            <PhotoUploader
                                elId="back-image"
                                isImageLoaded={backImage}
                                selectedImage={(file: any) => onUploadImgHandler(file, 'backImage')}
                                onRotted={() => console.log("rotted trigger")}
                                onReset={() => onRemoveImgHandler(backImageFileId, 'backImage')}
                            />
                            <small className="invalid-text">{t('form.invalid_message')}</small>
                        </div>
                    </Col>

                <Col lg={3} md={4} xs={6} className="mb-4">
                    { isShowAdditionalImageOne ? <div className="add-photo-box">
                      {
                        additionalImageOneLoading? <ImageUploadLoader height={130} width={130} />
                            : <img
                                className="selected-img"
                                src={additionalImageOne || photoPlaceholder}
                            />
                      }
                      <div className="photo-box-title"> {t('image_upload.add_photo')} </div>
                      <PhotoUploader
                          elId="additionalImageOne"
                          isImageLoaded={additionalImageOne}
                          selectedImage={(file: any) => onUploadImgHandler(file, 'additionalImageOne')}
                          onRotted={() => console.log("rotted trigger")}
                          onReset={() => onRemoveImgHandler(additionalImageOneFileId, 'additionalImageOne')}
                      />
                    </div> : null}
                  </Col>

                  <Col lg={3} md={4} xs={6} className="mb-4">
                    { isShowAdditionalImageTwo ? <div className="add-photo-box">
                      {
                        additionalImageTwoLoading? <ImageUploadLoader height={130} width={130} />
                            : <img
                                className="selected-img"
                                src={additionalImageTwo || photoPlaceholder}
                            />
                      }
                      <div className="photo-box-title"> {t('image_upload.add_photo')} </div>
                      <PhotoUploader
                          elId="additionalImageTwo"
                          isImageLoaded={additionalImageTwo}
                          selectedImage={(file: any) => onUploadImgHandler(file, 'additionalImageTwo')}
                          onRotted={() => console.log("rotted trigger")}
                          onReset={() => onRemoveImgHandler(additionalImageTwoFileId, 'additionalImageOne')}
                      />
                    </div> : null}
                  </Col>

                  <Col lg={3} md={4} xs={6} className="mb-4">
                    { isShowAdditionalImageThree ? <div className="add-photo-box">
                      {
                        additionalImageThreeLoading? <ImageUploadLoader height={130} width={130} />
                            : <img
                                className="selected-img"
                                src={additionalImageThree || photoPlaceholder}
                            />
                      }
                      <div className="photo-box-title"> {t('image_upload.add_photo')} </div>
                      <PhotoUploader
                          elId="additionalImageThree"
                          isImageLoaded={additionalImageThree}
                          selectedImage={(file: any) => onUploadImgHandler(file, 'additionalImageThree')}
                          onRotted={() => console.log("rotted trigger")}
                          onReset={() => onRemoveImgHandler(additionalImageThreeFileId, 'additionalImageThree')}
                      />
                    </div> : null}
                  </Col>

                  <Col lg={3} md={4} xs={6} className="mb-4">
                    { isShowAdditionalImageFour ? <div className="add-photo-box">
                      {
                        additionalImageFourLoading? <ImageUploadLoader height={130} width={130} />
                            : <img
                                className="selected-img"
                                src={additionalImageFour || photoPlaceholder}
                            />
                      }
                      <div className="photo-box-title"> {t('image_upload.add_photo')} </div>
                      <PhotoUploader
                          elId="additionalImageFour"
                          isImageLoaded={additionalImageFour}
                          selectedImage={(file: any) => onUploadImgHandler(file, 'additionalImageFour')}
                          onRotted={() => console.log("rotted trigger")}
                          onReset={() => onRemoveImgHandler(additionalImageFourFileId, 'additionalImageFour')}
                      />
                    </div> : null}
                  </Col>
                </Row>
                {/* End photos section  */}

                {/* Start height/width section  */}
                {
                    isShowHeightWidth? <>
                    <div className="text-center my-5">
                        <h5>{t("additional_information_required")}</h5>
                    </div>
                    <Row className="my-4">
                        <Col lg={6} md={6} xs={6}>
                     <div className={`${isCheckFormValidation && !width?'invalid':''}`}>
                            <InputGroup className="mb-3">
                                <Form.Control
                                type="number"
                                min="1"
                                max="100"
                                value={width}
                                placeholder={t('bag_width') || ''}
                                aria-label="Recipient's username"
                                aria-describedby="basic-addon2"
                                onChange={(e:any)=>onChangeInputFieldHandler(e, 'width')}
                                />
                                <InputGroup.Text id="photoWidth"
                                >cm</InputGroup.Text>
                            </InputGroup>
                            <small className="invalid-text">{t('form.invalid_message')}</small>
                            </div>
                        </Col>
                        <Col lg={6} md={6} xs={6}>
                     <div className={`${isCheckFormValidation && !height?'invalid':''}`}>
                            <InputGroup className="mb-3">
                                <Form.Control
                                    type="number"
                                    min="1"
                                    max="100"
                                    value={height}
                                    placeholder={t('bag_height') || ''}
                                    aria-label="Recipient's username"
                                    aria-describedby="basic-addon3"
                                    onChange={(e:any)=>onChangeInputFieldHandler(e, 'height')}
                                />
                                <InputGroup.Text id="photoHeight">cm</InputGroup.Text>
                                </InputGroup>
                                <small className="invalid-text">{t('form.invalid_message')}</small>
                            </div>
                        </Col>
                    </Row>
                    </>:<></>
                }
                {/* End height/width section  */}

                <div className="how-to-form-footer pt-3 pb-5 px-3 d-flex justify-content-between">
                    <Button disabled={isUploadedRequidPhoto()} onClick={() => navigate('/your-article') } className="round-btn" variant="dark" size="sm"> {t('return')} </Button>
                    <Button onClick={() => onSubmitBrandAndType() } className="round-btn" variant="dark" size="sm"> {t('continue')}  </Button>
                </div>

            </Container>
        </>
    );
};

export default EditArticle;