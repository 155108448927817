import { Card, Form, Button, Row, Col, Badge, Spinner } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react';
import { useDispatch } from "react-redux";
import { toast } from 'react-toastify';
import { useSelector } from "react-redux";
import { actionConfirm } from "helpers/common_helper";
import ConfirmRequestModal from "components/common/ConfirmRequestModal";
import { putBnsApi } from "helpers/api_helpers";

// Start import helpers
import {getBnsApi, post, put, postBnsPrivateSellerApi} from 'helpers/api_helpers';
import { PAYOUT_API } from "constants/apiEndPoints/bnsApi/payoutEndpoints";
import { ADDRESS_API } from "constants/apiEndPoints/bnsApi/addressEndpoints";
import { addToPayoutList } from "store/payout/actions";
import { addToAddressList } from "store/address/actions";
import { toastValues } from 'common/toastValues';
import { useTranslation } from "react-i18next";
import { CONFIRM_REQUEST_API } from "constants/apiEndPoints/bnsPrivateSeller/ConfirmRequestEndpoints";
import { REQUEST_BNS_API } from "constants/apiEndPoints/bnsApi/requestEndpoints";
import { addToShipment } from "store/shipment/actions";
import { SHIPMENT_API } from "constants/apiEndPoints/bnsApi/shipmentEndpoints";

const defaultState = {
    firstName: '',
    lastName: '',
    address: '',
    streetNo: '',
    postalCode: '',
    location: ''
};

const ConfirmRequestPage = (props:any) => {
    let totalPrice = 0;
    const { goBack, goNext } = props;
    const navigate = useNavigate();
    const { state } = useLocation();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { payouts } = useSelector(({ Payout }: { Payout: any }) => Payout);
    const { address } = useSelector(({ Address }: { Address: any }) => Address);
    const { authUser } = useSelector(({ Login }: { Login: any }) => Login);
    const { shipment } = useSelector(({ Shipment }: { Shipment: any }) => Shipment);
    const payoutValue = payouts?.payload || "";
    const addressValue = address?.payload || "";
    const [states, setStates] = useState<any>(defaultState);
    const [ user, setUser] = useState<any>({});
    const [isShowTermModal, setIsShowTermModal] = useState<boolean>(false);
    const [articles, setArticles] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isApiLoading, setIsApiLoading] = useState<boolean>(false);

    // payout start here
    const [paymentType, setPaymentType] = useState<string>(payoutValue?.paymentType || "bank");
    const [accountName, setAccountName] = useState<string>(payoutValue?.accountName || "");
    const [accountNumber, setAccountNumber] = useState<string>(payoutValue?.accountNumber || "");

    useEffect(() => {
        setUser(authUser);
        setStates({...states, ...addressValue});
    }, []);
    //End payout here
    useEffect(() => {
        fetchBnsRequest();
    }, []);

    const fetchBnsRequest= async () => {
        setIsLoading(true);
        try {
            const { data } = await getBnsApi(REQUEST_BNS_API.fetch_request_by_id(state));
            const filteredArticles = data.articles.filter(
                (article:any) => article.state === 'confirmed');

            setArticles(filteredArticles || []);
            setIsLoading(false);

        } catch (error) {
            setIsLoading(false);
            toast.error("Some things went wrong!", toastValues);
        }
    };

    const confirmRequestHandler  = async () => {
        const addressData = {
            userId: user.id,
            firstName: states?.firstName,
            lastName: states?.lastName,
            address: states?.address,
            streetNo: states?.streetNo,
            postalCode: states?.postalCode,
            location: states?.location
        };

        const payoutData = {
            userId: user.id,
            status: "pending",
            paymentType: paymentType,
            accountName: accountName,
            accountNumber: accountNumber
        };

        let upperCaseValue;
        if(paymentType === "bank"){
            upperCaseValue = "BANK_TRANSFER"
        } else {
            upperCaseValue = "COUPON"
        }

        const updateData = {
            purchase_request: {
                shipping_label: shipment?.payload?.labelUrl || "http://example.com",
                customer_email: user.email,
                payment_method: upperCaseValue,
                parcel_count: 2
            }
        }
        
        setIsApiLoading(true);

        try {
            // update_shipping_detail data put part
            const shipmentDetailResponse = await putBnsApi(ADDRESS_API.update_shipping_detail(state?.refId
            ), updateData);


            // Address data post part
            const responseAddressLocal = await post(ADDRESS_API.create(), addressData);
            if (responseAddressLocal.id) {
                dispatch(addToAddressList({}));
            }

            // Payout data post part
            const newPayout = await {...payoutData, addressId: responseAddressLocal.id };
            const responseOwnPayout:any = await post(PAYOUT_API.create(), newPayout);

            if (responseOwnPayout?.id) {
                dispatch(addToPayoutList({}));
            }

            // Update shipment own data.
            const shipmentData = {...shipment?.payload, addressId: responseAddressLocal.id, payoutId: responseOwnPayout.id }
            const responseOwnShipment = await put(SHIPMENT_API.update(shipmentData?.id), shipmentData);

            const privateSellerData ={
                private_seller: {
                    first_name: states?.firstName,
                    name: states?.firstName+" "+states?.lastName,
                    salutation: "Mr.",
                    title: "Dr.",
                    email: user.email,
                    phone: "+52 8111111111",
                    phone_mobile: "+52 8222222222",
                    address_street: states?.streetNo,
                    address_zipcode: states?.postalCode,
                    address_city: states?.location,
                    address_country: "Germany",
                    address_country_iso: "DE",
                    company: "Springfield Nuclear Power Plant",
                    bank_name: "Bank of Springfield",
                    bank_account_name: accountName,
                    bank_account_no: accountNumber,
                    bank_code: "12345678",
                    bank_iban: "GB29 NWBK 6016 1331 9268 19",
                    bank_swift: "BOFAUS3N",
                    comment: "Loves Donuts",
                    permission_newsletter_email: true,
                    partner_id: 1
                }
            }
    
            // Address data Private Seller api server
            const responsePrivateSeller = await postBnsPrivateSellerApi(CONFIRM_REQUEST_API.create(), privateSellerData);
            console.log(responsePrivateSeller);

            if (shipmentDetailResponse?.status === "UPDATED") {
                toast.success("Successfully created!", toastValues);
                dispatch(addToShipment({}));
            } else {
                toast.error("Some things went wrong!", toastValues);
            }

            setIsApiLoading(false);

        } catch (error) {
            setIsApiLoading(false);
            toast.error("Some things went wrong!", toastValues);
        }


        // // Address data post part
        // const responseAddressLocal = await post(ADDRESS_API.create(), addressData);
        // if (responseAddressLocal.id) {
        //     // toast.success("Successfully created!", toastValues);
        //     dispatch(addToAddressList({}));
        // } else {
        //     // toast.error("Some things went wrong!", toastValues);
        // }

        // // Payout data post part
        // const new_payout = await {...payoutData, addressId: responseAddressLocal.id }
        // const responsePayoutLocal = await post(PAYOUT_API.create(), new_payout);
        // if (responsePayoutLocal.id) {
        //     // toast.success("Successfully created!", toastValues);
        //     dispatch(addToPayoutList({}));
        // } else {
        //     // toast.error("Some things went wrong!", toastValues);
        // }

        // const privateSellerData ={
        //     private_seller: {
        //         first_name: states?.firstName,
        //         name: "Simpson",
        //         salutation: "Mr.",
        //         title: "Dr.",
        //         email: user.email,
        //         phone: "+52 8111111111",
        //         phone_mobile: "+52 8222222222",
        //         address_street: states?.streetNo,
        //         address_zipcode: states?.postalCode,
        //         address_city: states?.location,
        //         address_country: "Germany",
        //         address_country_iso: "DE",
        //         company: "Springfield Nuclear Power Plant",
        //         bank_name: "Bank of Springfield",
        //         bank_account_name: accountName,
        //         bank_account_no: accountNumber,
        //         bank_code: "12345678",
        //         bank_iban: "GB29 NWBK 6016 1331 9268 19",
        //         bank_swift: "BOFAUS3N",
        //         comment: "Loves Donuts",
        //         permission_newsletter_email: true,
        //         partner_id: 1
        //     }
        // }

        // // Address data Private Seller api server
        // const responsePrivateSeller = await postBnsPrivateSellerApi(CONFIRM_REQUEST_API.create(), privateSellerData);

        // console.log(responsePrivateSeller);
        // if (responsePrivateSeller.id) {
        //     toast.success("Successfully created!", toastValues);
        // } else {
        //     toast.error("Some things went wrong!", toastValues);
        // }


        navigate("/accept-request");
    };

    const onRequestRemoveHandler = () => {
        const config = {
            title:`${t('confirm_delete.are_you_sure')}`,
            text: `${t('confirm_delete.you_cannot_undo_this')}`,
            confirmButtonText: `${t('confirm_delete.confirm_final_button')}`,
            cancelButtonText: `${t('confirm_delete.interrupt_final_button')}`
        };

        actionConfirm(config).then(({isConfirmed}:any)=>{
            if(isConfirmed) {
                // Here define remove api call
                setIsShowTermModal(!isShowTermModal);
            }
        });
    };
    // End events handlers

    return (
        <>
            <>
                <Row className="confirm-request">
                    <Col md={3} xs={12}>
                        <Card className="request-card">
                            <Card.Body>
                                <Card.Title>{t('your_articles')}</Card.Title>
                                { isLoading? <div className="content-middle-sm">
                                        <Spinner animation="border" variant="warning" />
                                    </div>:
                                    articles.map((data:any) => {
                                        totalPrice += parseFloat(data.purchase_price);
                                        return(
                                            <Row className="request-details" key={data.id}>
                                                <Col>{data.name}</Col>
                                                <Col className="text-end">{data.purchase_price || 0} {data.price} ,-</Col>
                                            </Row>
                                        )
                                    })
                                }
                                {/*<Row className="request-details">
                                    <Col>Prada Damen / Mantel</Col>
                                    <Col className="text-end">Preisvorschlag 26 ,-</Col>
                                </Row>
                                <Row className="request-details">
                                    <Col>Gucci Damen / Shirt</Col>
                                    <Col className="text-end">65 ,-</Col>
                                </Row>
                                <Row className="request-details">
                                    <Col>Michael Kors Damen / Tasche</Col>
                                    <Col className="text-end">112 ,-</Col>
                                </Row>*/}
                                <Row className="">
                                    <Col>
                                        <strong>{t('in_total')}</strong>
                                    </Col>
                                    <Col className="text-end">
                                        <strong>{totalPrice} ,-</strong>
                                    </Col>
                                </Row>
                                <Button className="round-btn mt-3 ms-2" variant="dark">{t('change_button')}</Button>
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={3} xs={12}>
                        <Card className="request-card">
                            <Card.Body>
                                <Card.Title className="pb-2">{t('payout_method_confirm_request_page')}</Card.Title>
                                <div className="mx-auto payment-options justify-content-between">

                                    <Card className={`card-item ${paymentType === 'coupon'? 'active': ''}`}>
                                        <Card.Body>
                                            <Card.Title>
                                                <i className="fa-solid fa-desktop"></i>
                                                {paymentType === 'coupon'? <Badge className="default-badge checked-icon">
                                                    <i className="fa fa-check"></i>
                                                </Badge> : null}
                                            </Card.Title>
                                            <Card.Text className="text-uppercase">{t('coupon')}</Card.Text>
                                        </Card.Body>
                                    </Card>


                                    <Card className={`card-item ${paymentType === 'bank'? 'active': ''}`}>
                                        <Card.Body>
                                            <Card.Title>
                                                <i className="fa-solid fa-building-columns"></i>
                                                {paymentType === 'bank'? <Badge className="default-badge checked-icon">
                                                    <i className="fa fa-check"></i>
                                                </Badge>:<></>}
                                            </Card.Title>
                                            <Card.Text className="text-uppercase">{t('transfer')}</Card.Text>
                                        </Card.Body>
                                    </Card>
                                </div>
                                { paymentType === 'bank'? <div>
                                    <Form.Group className="mb-3" controlId="formBasicEmail">
                                        <Form.Control disabled={true} type="text"
                                                      placeholder={`${t('account_owner')}`} value={accountName}/>
                                    </Form.Group>

                                    <Form.Group className="mb-3" controlId="formBasicPassword">
                                        <Form.Control disabled={true} type="text" placeholder="IBAN" value={accountNumber}/>
                                    </Form.Group>
                                </div>: <></> }
                            </Card.Body>
                        </Card>
                    </Col>

                    <Col md={3} xs={12}>
                        <Card className="request-card">
                            <Card.Body>
                                <Card.Title className="pb-2">{t('shipment')}</Card.Title>
                                <Row className="row-margin-custom">
                                    <Col>
                                        <Form.Group className="mb-3" controlId="vorname">
                                            <Form.Control disabled={true} type="text"
                                                          placeholder={`${t('first_name')}`}
                                                          value={states?.firstName}/>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="nachname">
                                            <Form.Control disabled={true} type="text"
                                                          placeholder={`${t('last_name')}`}
                                                          value={states?.lastName}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="row-margin-custom">
                                    <Col>
                                        <Form.Group className="mb-3" controlId="adresszusatz">
                                            <Form.Control disabled={true} type="text"
                                                          placeholder={`${t('address_supplement')}`}
                                                          value={states?.address}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="row-margin-custom">
                                    <Col>
                                        <Form.Group className="mb-3" controlId="streetNane">
                                            <Form.Control disabled={true} type="text"
                                                          placeholder={`${t('street_name')}`}
                                                          value={states?.streetName}/>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="straße">
                                            <Form.Control disabled={true} type="text"
                                                          placeholder={`${t('street_no')}`}
                                                          value={states?.streetNo}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row className="row-margin-custom">
                                    <Col>
                                        <Form.Group className="mb-3" controlId="postleitzahl">
                                            <Form.Control disabled={true} type="text"
                                                          placeholder={`${t('postal_code')}`}
                                                          value={states?.postalCode}/>
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="ort">
                                            <Form.Control disabled={true} type="text"
                                                          placeholder={`${t('location')}`}
                                                          value={states?.location}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col md={3} xs={12}>
                        <Card className="request-card">
                            <Card.Body>
                                <Card.Title className="text-center text-uppercase">
                                    {t('our_range')}
                                </Card.Title>
                                <Card.Title className="pb-2 text-center text-uppercase text-success">
                                    {totalPrice} €*
                                </Card.Title>
                                <div className="text-center">
                                    <small>
                                        {t('our_range_description')}
                                    </small>
                                </div>
                                <div className="d-flex justify-content-between py-5">
                                    <Button className={`round-btn ${isApiLoading? 'disabled':''}`} variant="dark" onClick={() => isApiLoading?'':confirmRequestHandler()}>
                                        {isApiLoading ? (
                                         <><Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> {t('loading')}...</>
                                        ) : ( t('confirm_delete.confirm_button') )}
                                    </Button>
                                    <Button className="round-btn" variant="outline-dark" onClick={() => onRequestRemoveHandler()}>{t('reject_button')}</Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </>

            <ConfirmRequestModal
                id={state?.refId}
                show={isShowTermModal}
                onHide={() => setIsShowTermModal(!isShowTermModal) }
            />
        </>
    );
};

export default ConfirmRequestPage;