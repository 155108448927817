import { Container, Card, Button, Row, Col, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useState, useEffect, FormEvent } from "react";
import { toast } from 'react-toastify';

// Start import images
import { clock, reqProduct1, tick } from "assets/image/image";

// Start import helpers 
import { toastValues } from 'common/toastValues';
import { get, getBnsApi, put, putBnsApi } from 'helpers/api_helpers';
import { actionConfirm, getRequstStatus } from 'helpers/common_helper';
import { REQUEST_BNS_API } from "constants/apiEndPoints/bnsApi/requestEndpoints";
import { REQUEST_API } from "constants/apiEndPoints/requestEndpoints";
import { useTranslation } from "react-i18next";

/*const ReqStatus = ( {request}:any ) => {
  const stateObj:any = getRequstStatus(request);
  return <div className={`mt-2 ${stateObj.color}`}>{ stateObj.statusText }</div>;
};*/

const MyRequestsPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const [requests, setRequests] = useState<any>([]);
  const [refArr, setRefArr] = useState<any>([]);

  useEffect(() => {
    fetchUserRef();
  }, []);

  useEffect(() => {
    fetchReqests();
  }, [refArr]);

  const goToRequestDetails = (e:FormEvent<EventTarget>, id:string) => {
    e.preventDefault();
    navigate(`/my-requests/${id}`);
  };

  //Fetch all user ref list
  const fetchUserRef = async () => {
    try {
      const { data } = await get(REQUEST_API.fetch_all_ref());
      setRefArr(data || []);
    } catch (error) {
      toast.error("Some things went wrong!", toastValues);
    }
  };

  //Fetch all user request list
  const fetchReqests = async () => {
    setLoading(true);
    try {
      const refWithoutArr = await refArr.map((s:any) => `"${s}"`).join(", ");
      const { data } = await getBnsApi(REQUEST_BNS_API.fetch_my_requests(refWithoutArr));
      setRequests( data || []);
      setTimeout(()=>{ setLoading(false); }, 1500);
    } catch (error) {
      setLoading(false);
      toast.error("Some things went wrong!", toastValues);
    }
  };

  // Start events handlers
  const onRequestRemoveHandler = async (id:string) => {
    const config = {
      title:`${t('confirm_delete.are_you_sure')}`,
      text: `${t('confirm_delete.you_cannot_undo_this')}`,
      confirmButtonText: `${t('confirm_delete.confirm_button')}`,
      cancelButtonText: `${t('confirm_delete.interrupt_button')}`
    };

    try {
      const { isConfirmed }: any = await actionConfirm(config);
      if (isConfirmed) {
        // update in live api
        const response = await putBnsApi(REQUEST_BNS_API.rejected_by_customer(id), {});
        if (response.status === "UPDATED") {
          fetchReqests()
          toast.success("Successfully Updated!", toastValues);
        }
        // update in local api
        const localResponse = await put(REQUEST_API.update(id), {status: "cancelled"});
      }
    } catch (error) {
      toast.error("Some things went wrong!", toastValues);
    }
  };
  // End events handlers

  const getCancelledStatus = (data:any) => {
    if(data.every((obj:any) => obj.state === 'cancelled')){
      return false
    } else{
      return true
    }
  }

  // Start locale helpers
  const getReqArticlesTotal = ( request:any ) => {
    const filteredArticles = request.articles.filter(
        (article:any) =>
            article.state !== 'cancelled' && article.state !== 'rejected_by_customer'
    );

    return filteredArticles.length;
    // return request?.articles?.length || 0;
  };

  const getRequestFirstImage = (props: any) => {
    const data = props.articles;
    let url;

    for (const item of data) {
      if (item.front_image || item.back_image) {
        url = item.front_image || item.back_image;
        break;
      }
    }

    return <img className="product-info-img" src={url} onError={(e) => {
      e.currentTarget.src = reqProduct1;
    }} />;
  }
  // End locale helpers

  return (
      <>
        <Container fluid className="background-white container-min-height">
          <Card.Title className="page-title text-uppercase">
            {t('my_inquiries')}
          </Card.Title>
          <div className="request-list">
            {
              loading? <div className="content-middle">
                    <Spinner animation="border" variant="warning" />
                  </div>:
                  <>
                    {
                      (requests.length < 0) ? <div className="content-middle">{t('find_nothing_message')}</div> : <Row>
                        {
                          requests.map((request: any, index: number) => {
                            return (
                                (request.state === "cancelled" || request.state === "rejected_by_customer") ? null :
                                    ( getCancelledStatus(request?.articles) ? <Col md={3} xs={12} key={index}>
                                      <Card onClick={() => navigate(`/my-requests/${request.reference_number}`)} className="request-product cursor_pointer" style={{ minWidth: "18rem" }}>
                                        <Card.Body>
                                          <div className="product-info">
                                            <div>
                                              <div>{t('referenz_nr')}: {request.reference_number}</div>
                                              <div>{getReqArticlesTotal(request)} {t('article_name')}</div>

                                            </div>
                                            <div className="how-to-img-box mr-10">
                                              {getRequestFirstImage({ articles: request?.articles })}
                                            </div>
                                          </div>
                                          {/* Request status */}

                                          {request.state === "in_progress" ? (<p className="req-state-orange">{t('err_message.in_progress')}</p>)
                                              :
                                              (request.state === "waiting_for_customer" ? (<p className="req-state-red">{t('err_message.waiting_for_customer_offer')}</p>)
                                                  :
                                                  (request.state === "offer_accepted" ? ( <p className="req-state-white"><img className="click-icon" src={tick} title="tick-icon"/> {t('err_message.offer-accepted')}</p>)
                                                      :
                                                      (request.state === "open" ? (<p className="req-state-gray">
                                                            {t('err_message.open')}</p>)
                                                          :
                                                          (request.state === "offer_available" ? (<p className="req-state-green">
                                                                {t('err_message.offer_available')}</p>)
                                                              :
                                                              <p className="req-state-red">{t('err_message.not_defined')}</p>))))}
                                          {/* <p className="req-state-orange"><img className="click-icon" src={clock} title="clck-icon"/> {t('err_message.in_progress')}</p> */}

                                          {/* Request status */}
                                          <div>
                                            { request.state === "rejected" ? null :<Button
                                                className="mr-10"
                                                variant="dark"
                                                onClick={(e) => goToRequestDetails(e, request.reference_number)}
                                            > {t('open_button')} </Button>}
                                            {request.state === "offer_accepted" ? null: <div className="round-btn btn btn-sm label-round-btn" onClick={() => onRequestRemoveHandler(request.reference_number)}>{t('cancel_button')}</div>}
                                          </div>
                                        </Card.Body>
                                      </Card>
                                    </Col> : null)
                            );
                          })
                        }
                      </Row>
                    }
                  </>
            }
          </div>
        </Container>
      </>
  );
};

export default MyRequestsPage;
