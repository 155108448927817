import { Container, Nav, Navbar, Dropdown, Button } from 'react-bootstrap';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useState, FormEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

// Start import helppers
import { logoutUserSuccess } from 'store/auth/login/actions';
import { toastValues } from 'common/toastValues';

// Start import images
import {
  desktopLogo,
  mobileLogo,
  enLanguageFlag,
  deLanguageFlag,
} from 'assets/image/image';

const Header = (props:any) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { authUser } = useSelector(({ Login }: { Login: any }) => Login);
  const [isAuth, setIsAuth] = useState<boolean>(false);
  const [language, setLanguage] = useState<string>(localStorage.getItem('i18nextLng') || 'de');
  const { isMobile } = useSelector(({ Device }: { Device: any }) => Device);

  useEffect(()=>{
    setIsAuth(!!authUser);
  }, [authUser]);

  const onLanguageChangeHandler = (lng:string) => {
    setLanguage(lng);
    i18n.changeLanguage(lng);
    localStorage.setItem('i18nextLng', lng);
  }

  const onNavigatehandler =(e: FormEvent<EventTarget>, path: string): void => {
    if(path && path === '/my-requests') {
      if(!isAuth) {
        navigate('/login',{ state: {fromPathname: '/my-requests' } });
      } else navigate(path)

    } else if(path) navigate(path);
  };

  const onLogoutHandler =(e: FormEvent<EventTarget>): void => {
    dispatch(logoutUserSuccess());
    toast.success("Successfully logout", toastValues);
    navigate('/');
  };

  const getName =(fullName:string) => {
    if(isMobile) return fullName
    else return `${fullName?.slice(0, 8)}`;
  };

  return (
      <Navbar fixed="top" className={isMobile?`mobile-view`:`desktop-view`} collapseOnSelect expand="lg" bg="light" variant="light">
        <Container fluid>
          <div className="toggle-logo-container cursor-pointer">
            <div> <Navbar.Toggle aria-controls="responsive-navbar-nav" /> </div>
            <Navbar.Brand onClick={()=> navigate('/')}><img src={isMobile? mobileLogo:desktopLogo} /> </Navbar.Brand>
          </div>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link onClick={(e)=> onNavigatehandler(e, '/')} href="#">
                {t('home')}</Nav.Link>
              {/* <Nav.Link href="#about-us">Über Uns</Nav.Link>
            <Nav.Link href="#brands">Marken</Nav.Link>
            <Nav.Link href="#purchase-price">Ankaufspreise</Nav.Link>
            <Nav.Link href="#sell">Verkaufen</Nav.Link>
            <Nav.Link href="#faq">Fragen & Antworten</Nav.Link>
            <Nav.Link href="#job">Jobs</Nav.Link> */}
              <Nav.Link className='iframe-hide' onClick={(e)=> onNavigatehandler(e, '/guidelin')} href="#"> {t('create_request')}</Nav.Link>
              <Nav.Link className='iframe-hide' onClick={(e)=> onNavigatehandler(e, '/my-requests')} href="#">{t('my_requests')}</Nav.Link>
              {/* {
                isAuth? <>
                  <Nav.Link disabled onClick={(e)=> onNavigatehandler(e, '/address')} href="#">{t('addresses')}</Nav.Link>
                  <Nav.Link disabled onClick={(e)=> onNavigatehandler(e, '/payout')} href="#">{t('payout_method')}</Nav.Link>
                </>:<></>
              } */}
            </Nav>
            <Nav>
              <Dropdown>
                <Dropdown.Toggle className="language-toggle-btn mr-5" variant="primary" id="language"
                                 size="sm"
                >
                  <img src={language ==='de'?deLanguageFlag:enLanguageFlag} />
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item onClick={()=>onLanguageChangeHandler('en')}> <img src={enLanguageFlag} className="mr-1" /> English </Dropdown.Item>
                  <Dropdown.Item onClick={()=>onLanguageChangeHandler('de')}> <img src={deLanguageFlag} className="mr-1" /> Germany</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              {
                isAuth? <Dropdown>
                      <Dropdown.Toggle className="language-toggle-btn mr-5 mb-2" variant="default" id="profile"
                                       size="sm"
                      >
                        <i className="fa fa-user"></i> {getName(authUser?.email)}
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item disabled onClick={(e)=> onNavigatehandler(e, '/profile')}> {t('profile')} </Dropdown.Item>
                        <Dropdown.Item onClick={(e)=>onLogoutHandler(e)}> {t('logout')} </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown> :
                    <Button variant="info" className="nav-round-btn" size="sm"
                            onClick={(e)=> onNavigatehandler(e, '/login')}
                    > {t('login')} </Button>
              }

            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
  );
};

export default Header;
