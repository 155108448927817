// Start Import package
import { Fragment } from 'react';
import { Button } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { useTranslation } from 'react-i18next';

// Start import helpers

const RequestCard = (props:any) => {
    const { image, onClick, arrowIcon } = props;
    const { t } = useTranslation();

    return (
        <Fragment>
            <Card onClick={(onClick)} className="product-card" style={{ minWidth: '18rem' }}>
                <Card.Body>
                    <div className="card-img" style={{backgroundImage: `url(${image})`}}> </div>
                    <div className='d-flex justify-content-center mt-3'><Button className="round-btn" variant="dark" size="sm"> {t('create_request')} <img className="ms-1" src={arrowIcon} width={30} alt="" /> </Button></div>
                </Card.Body>
            </Card>
        </Fragment>
    );
};

export default RequestCard;
