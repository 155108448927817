import { Container, Card, Badge, Form, Button, Row, Col } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Stepper, Step } from 'react-form-stepper';
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Start import helpers
import { addToPayoutList, clearPayout } from "store/payout/actions";
import { stepperStyleConfig, stepperConnectorStyleConfig } from 'helpers/common_helper';
import {
    bestsecretLogoImg,
    pngfindLogoImg,
    lbogoImg,
} from '../assets/image/image';

// Start import components
import ShippingPage from './ShippingPage';
import ConfirmRequestPage from "./ConfirmRequestPage";
import { getBnsApi } from "helpers/api_helpers";
import { REQUEST_BNS_API } from "constants/apiEndPoints/bnsApi/requestEndpoints";
import { toast } from "react-toastify";
import { toastValues } from "common/toastValues";

const PayoutPage = () => {
    let totalPrice = 0;
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { state } = useLocation();
    const { authUser } = useSelector(({ Login }: { Login: any }) => Login);

    const [ user, setUser] = useState<any>({});
    const [paymentType, setPaymentType] = useState<string>("bank");
    const [accountName, setAccountName] = useState<string>("");
    const [accountNumber, setAccountNumber] = useState<string>("");
    const [partnerId, setPartnerId] = useState<any>(null);
    const [coupon, setCoupon] = useState<any>(null);
    const [activeStep, setActiveStep] = useState<number>(0);
    const [articles, setArticles] = useState<any>([]);

    useEffect(() => {
        setUser(authUser);

        // set partner information
        if(state?.partnerId && state?.partnerCoupon) {
            setPartnerId(state?.partnerId);
            setCoupon(state?.partnerCoupon);
            setPaymentType('coupon');
        }
    }, []);

    useEffect(() => {
        if(!state) {
            navigate('/');
        } else {
            fetchBnsRequest();
        }
    }, []);

    const fetchBnsRequest= async () => {
        // setIsLoading(true);
        try {
            const { data } = await getBnsApi(REQUEST_BNS_API.fetch_request_by_id(state));
            const filteredArticles = data.articles.filter(
                (article:any) => article.state === 'confirmed');

            setArticles(filteredArticles || []);
            // setIsLoading(false);

        } catch (error) {
            // setIsLoading(false);
            toast.error("Some things went wrong!", toastValues);
        }
    };

    const totalCount = () => {
        articles.forEach((data:any) => {
            totalPrice += parseFloat(data.purchase_price)
        })
        return totalPrice || 0;
    }

    const onPaymentTypeHandler = (type:string) => {
        setPaymentType(type);
    };

    const addPayout = () => {
        const payout = {
            userId: user.id,
            addressId:"",
            refId:"",
            status: "pending...",
            paymentType: paymentType,
            accountName: accountName,
            accountNumber: accountNumber
        };

        dispatch(clearPayout());
        dispatch(addToPayoutList(payout));
        setActiveStep(1);
        // navigate("/shipping");
    };

    const stepsList = [
        { label: t('payout'), position: 0, classes:"payout-step" },
        { label: t('shipping'), position: 1, classes:"shipping-step" },
        { label: t('confirm'), position: 2, classes:"confirm-step" }
    ];
    // End events handlers

    return (
        <>
            <Container fluid className="background-white container-min-height">
                <Card.Title className="page-title text-uppercase"> {t('CHOOSE_PAYOUT')}</Card.Title>

                <Stepper activeStep={activeStep}
                         className="request-create-stepper"
                         connectorStateColors={true}
                         styleConfig={stepperStyleConfig}
                         connectorStyleConfig={stepperConnectorStyleConfig}
                >
                    {
                        stepsList.map((stepItem:any, index:number)=> {
                            return ( <Step key={`stepHeader_${index}`} className={`${stepItem?.classes || ''}`} label={stepItem.label} /> )
                        })
                    }
                </Stepper>

                <div className="stepper-container-box">
                    <div className="stepper-content">
                        {/* Start payout items field */}
                        {
                            !!(activeStep === 0)?
                                <>
                                    <Row>
                                        <Col lg={3} md={3} xs={12}> </Col>
                                        <Col lg={6} md={6} xs={12}>
                                            <div className="text-center pb-5"> {t('payout_details')}</div>
                                            <div className="payout-list">
                                                <div className="mx-auto payout-options justify-content-center">

                                                    <Card className={`text-center payout-item ${paymentType === 'coupon'? 'active': ''}`} onClick={() => onPaymentTypeHandler("coupon")}>
                                                        <Card.Body>
                                                            <div className="py-3">
                                                                <Card.Title>
                                                                    <i className="fa-solid fa-desktop"></i>
                                                                    {paymentType === 'coupon'? <Badge className="default-badge checked-icon"><i className="fa fa-check"></i></Badge> : null}
                                                                </Card.Title>
                                                                <Card.Text className="text-uppercase">{t('coupon')}</Card.Text>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>

                                                    <Card className={`text-center payout-item ${paymentType === 'bank'? 'active': ''}`} onClick={() => onPaymentTypeHandler("bank")}>
                                                        <Card.Body>
                                                            <div className="py-3">
                                                                <Card.Title>
                                                                    <i className="fa-solid fa-building-columns"></i>
                                                                    {paymentType === 'bank'? <Badge className="default-badge checked-icon"><i className="fa fa-check"></i></Badge> : null}
                                                                </Card.Title>
                                                                <Card.Text className="text-uppercase">{t('transfer')}</Card.Text>
                                                            </div>
                                                        </Card.Body>
                                                    </Card>

                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={3} md={3} xs={12}> </Col>
                                    </Row>
                                    <Row className="mb-4">
                                        <Col lg={3} md={3} xs={12}> </Col>
                                        <Col lg={6} md={6} xs={12}>
                                            {paymentType === 'coupon'? <div className="text-center">
                                                <strong>{t('option voucher')}</strong>
                                                <p>{t('option voucher description.option voucher description_one')}
                                                    <b> {t('option voucher description.option voucher description_two')}</b>{t('option voucher description.option voucher description_three')}
                                                </p>
                                                <p>{t('following vouchers')}</p>
                                                <div className="voucher-box">
                                                    <div className="partner_logo">
                                                        <img src={pngfindLogoImg} />
                                                    </div>
                                                    <div className="partner_logo">
                                                        <img src={bestsecretLogoImg} />
                                                    </div>
                                                    <div className="partner_logo">
                                                        <img src={lbogoImg} />
                                                    </div>
                                                </div>
                                            </div>:<>
                                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                                    <Form.Control type="text" placeholder="Erika Mustermann" defaultValue={accountName} onChange={(e)=>setAccountName(e.target.value)}/>
                                                </Form.Group>

                                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                                    <Form.Control type="text" placeholder="DE 2340 234 2345 39" defaultValue={accountNumber} onChange={(e)=>setAccountNumber(e.target.value)} />
                                                </Form.Group>
                                            </>}
                                        </Col>
                                        <Col lg={3} md={3} xs={12}> </Col>
                                    </Row>

                                    <Row className="pb-4">
                                        <Col lg={3} md={3} xs={12}> </Col>
                                        <Col lg={6} md={6} xs={12}>
                                            <Card>
                                                <Card.Body className="text-center">
                                                    <Card.Title className="page-title pb-0 text-uppercase mb-0"> {t('our_range')}</Card.Title>
                                                    {/* <Card.Text className="text-success mb-1">{t('10% bonus text')}</Card.Text> */}
                                                    <h2 className="text-success fw-bold mb-3">{totalCount()} €</h2>
                                                    <Button className="round-btn mb-4" variant="dark" onClick={() => addPayout()} >{t('continue_sender_address')} </Button>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col lg={3} md={3} xs={12}> </Col>
                                    </Row>
                                </>:<></>
                        }

                        {/* Start shipping items field */}
                        {
                            activeStep === 1? <>
                                <ShippingPage goBack={(setActiveStep)} goNext={(setActiveStep)} request={state}/>
                            </>:<></>
                        }

                        {/* Start payout overview items field */}
                        {
                            activeStep === 2? <>
                                <ConfirmRequestPage goBack={(setActiveStep)} goNext={(setActiveStep)} request={state}/>
                            </>:<></>
                        }
                    </div>
                </div>


                {/*
                <Row>
                    <Col lg={3} md={3} xs={12}> </Col>
                    <Col lg={6} md={6} xs={12}>
                        <Card.Title className="page-title text-uppercase"> {t('CHOOSE_PAYOUT')}</Card.Title>
                        <div className="text-center pb-5"> {t('payout_details')}</div>

                        <div className="payout-list">
                            <div className="mx-auto payout-options justify-content-center">

                                <Card className={`text-center payout-item ${paymentType === 'coupon'? 'active': ''}`} onClick={() => onPaymentTypeHandler("coupon")}>
                                    <Card.Body>
                                        <div className="py-3">
                                            <Card.Title>
                                                <i className="fa-solid fa-desktop"></i>
                                                {paymentType === 'coupon'? <Badge className="default-badge checked-icon"><i className="fa fa-check"></i></Badge> : null}
                                            </Card.Title>
                                            <Card.Text className="text-uppercase">{t('coupon')}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>

                                <Card className={`text-center payout-item ${paymentType === 'bank'? 'active': ''}`} onClick={() => onPaymentTypeHandler("bank")}>
                                    <Card.Body>
                                        <div className="py-3">
                                            <Card.Title>
                                                <i className="fa-solid fa-building-columns"></i>
                                                {paymentType === 'bank'? <Badge className="default-badge checked-icon"><i className="fa fa-check"></i></Badge> : null}
                                            </Card.Title>
                                            <Card.Text className="text-uppercase">{t('transfer')}</Card.Text>
                                        </div>
                                    </Card.Body>
                                </Card>

                            </div>
                        </div>

                        {paymentType === 'bank' ? <Row className="mb-4">
                            <Col lg={12} md={12} xs={12}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Control type="text" placeholder="Erika Mustermann" defaultValue={accountName} onChange={(e)=>setAccountName(e.target.value)}/>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Control type="text" placeholder="DE 2340 234 2345 39" defaultValue={accountNumber} onChange={(e)=>setAccountNumber(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row> : null}

                        <div className="d-flex justify-content-between py-2 pb-5">
                            <Button className="round-btn" variant="outline-dark" onClick={() => navigate(-1)}>{t('return')}</Button>
                            <Button className="round-btn" variant="dark" onClick={() => addPayout()} >{t('continue')} </Button>
                        </div>
                    </Col>
                    <Col lg={3} md={3} xs={12}> </Col>
                </Row> */}
            </Container>
        </>
    );
};

export default PayoutPage;
