import { Container, Card, Button, Col, Row, Form, InputGroup } from "react-bootstrap";
import { useNavigate, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from 'react-toastify';
import { toastValues } from 'common/toastValues';

// Start import components
import PhotoRestrictionsModal from "components/common/PhotoRestrictionsModal";
import PhotoUploader from "components/common/PhotoUploader";
import ImageUploadLoader from "components/common/ImageUploadLoader";

// Start import images
import {
  photoPlaceholderFront,
  photoPlaceholderBack,
  photoPlaceholder
} from "assets/image/image";

// Start import helpers
import { clearSelectRequest, addToRequest } from "store/request/actions";
import { FILE_API } from "constants/apiEndPoints/fileEndpoints";
import { CART_API } from 'constants/apiEndPoints/bnsApi/cartEndpoints';
import { post, del } from 'helpers/api_helpers';
import { useTranslation } from "react-i18next";
import { map, difference, filter } from "lodash-es";

const additionalImgKeys = ['additionalImageOne', 'additionalImageTwo', 'additionalImageThree', 'additionalImageFour'];

const PhotoPage = () => {
  const [user, setUser] = useState<any>({});

  useEffect(()=>{
    setUser(authUser);
  }, []);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { state }:any = useLocation();
  const { selectRequest } = useSelector(({ Request }: { Request: any }) => Request);
  const [frontImgLoading, setFrontImgLoading] = useState<boolean>(false);
  const [backImgLoading, setBackImgLoading] = useState<boolean>(false);
  const { authUser } = useSelector(({ Login }: { Login: any }) => Login);
  const [isShowTermModal, setIsShowTermModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(state?.isEdit || false);
  const [article, setArticle] = useState<any>(state?.article || {});
  const [fromPathname, setFromPathname] = useState<string>(state?.fromPathname || '');
  const [frontImage, setFrontImage] = useState<any>();
  const [frontImageFileId, setFrontImageFileId] = useState<any>();
  const [isShowfrontImage, setIsShowfrontImage] = useState<boolean>(true);
  const [backImage, setBackImage] = useState<any>();
  const [backImageFileId, setBackImageFileId] = useState<any>();
  const [isShowbackImage, setIsShowbackImage] = useState<boolean>(true);
  const [isShowHeightWidth, setIsShowHeightWidth] = useState<boolean>(false);
  const [width, setWidth] = useState<any>('');
  const [height, setHeight] = useState<any>('');
  const [isCheckFormValidation, setIsCheckFormValidation] = useState<boolean>(false);

  const [additionalImages, setAdditionalImages] = useState<any>([]);
  const [isShowAdditionalImage, setIsShowAdditionalImage] = useState<boolean>(true);
  const [additionalImageLoading, setAdditionalImageLoading] = useState<boolean>(false);
  const [additionalImage, setAdditionalImage] = useState<any>();
  const [additionalImageFileId, setAdditionalImageFileId] = useState<any>();

  useEffect(() => {
    if(!(selectRequest && selectRequest.uid)) {
      navigate('/guidelin');
    }

    if(!isEdit) {
      setIsShowTermModal(!isShowTermModal);
    }

    if(isEdit) {
      setFrontImage(article.front_image)
      setBackImage(article.back_image)
    }

    if(isEdit) {
      if(article.front_image){
        setIsShowfrontImage(false)
      } else if(article.back_image){
        setIsShowbackImage(false)
      }
    }
    setIsShowTermModal(!isShowTermModal);

    if(getLengthCategory(selectRequest.major_category)){
      setIsShowHeightWidth(true);
    } else setIsShowHeightWidth(false);

  }, []);

  // Start events handler
  const onUploadImgHandler = async (file:any, fieldName:string) => {

    if(fieldName === 'frontImage') setFrontImgLoading(true);
    else if(fieldName === 'backImage') setBackImgLoading(true);

    try {
      // Start file upload
      const blob = file.slice(0, file.size, "image/jpeg");
      const newFile = new File([blob], file.name, { type: "image/jpeg" });
      // Build the form data - You can add other input values to this i.e descriptions, make sure img is appended last
      const formData = new FormData();
      formData.append("file", newFile);
      formData.append("requestUid", selectRequest?.requestUid);
      if(user?.id) formData.append("userId", user.id);
      formData.append("articleUid", selectRequest?.uid);
      const { data } = await post(FILE_API.create(), formData, {isFile: true});

      if(data) {
        if(fieldName === 'frontImage') {
          setFrontImage(data.publicUrl);
          setFrontImageFileId(data.id);
        } else if(fieldName === 'backImage') {
          setBackImage(data.publicUrl);
          setBackImageFileId(data.id);
        }

        setTimeout(()=>{
          if(fieldName === 'frontImage') setFrontImgLoading(false);
          else if(fieldName === 'backImage') setBackImgLoading(false);
        }, 1000);
      }
    } catch (error) {

      if(fieldName === 'frontImage') setFrontImgLoading(false);
      else if(fieldName === 'backImage') setBackImgLoading(false);
    }

  };


  const onUploadAdditionImgHandler = async (file:any, fieldName:string) => {

    if(fieldName === 'additionalImage') setAdditionalImageLoading(true);

    try {
      // Start file upload
      const blob = file.slice(0, file.size, "image/jpeg");
      const newFile = new File([blob], file.name, { type: "image/jpeg" });
      // Build the form data - You can add other input values to this i.e descriptions, make sure img is appended last
      const formData = new FormData();
      formData.append("file", newFile);
      formData.append("requestUid", selectRequest?.requestUid);
      if(user?.id) formData.append("userId", user.id);
      formData.append("articleUid", selectRequest?.uid);
      const { data } = await post(FILE_API.create(), formData, {isFile: true});

      if(data) {
        if(fieldName === 'additionalImage') {
          const additionalImagesList:any = [...additionalImages];
          const addedKeys = map(additionalImagesList, 'key');
          const extraKeys = difference(additionalImgKeys, addedKeys);

          additionalImagesList.push({
            key: extraKeys[0],
            url: data.publicUrl,
            fileId: data.id,
          })

          setAdditionalImages(additionalImagesList);
        };

        setTimeout(()=>{
          if(fieldName === 'additionalImage') setAdditionalImageLoading(false);
        }, 1000);
      }
    } catch (error) {
      if(fieldName === 'additionalImage') setAdditionalImageLoading(false);
    }

    setAdditionalImage('');
    setAdditionalImageFileId('');
  };

  const isValidPhotoForm = () => {
    if(isShowHeightWidth) {
      if(!(height && width)) return false;
    }

    return !!(frontImage && backImage);
  };

  const getLengthCategory = (category:string) => {
    return (['men-bags', 'women-bags'].indexOf(category) !== -1)
  }

  const onRemoveImgHandler = async (fileId:any, fieldName:string) => {

    try {
      const { status } = await del(FILE_API.delete(fileId));
      if(status) {
        if(fieldName === 'frontImage') {
          setFrontImage(null);
          setFrontImageFileId(null);
        } else if(fieldName === 'backImage') {
          setBackImage(null);
          setBackImageFileId(null);
        }
      }
    } catch (error) {
      console.log('error:', error);
    }
  };

  const onRemoveAdditionalImgHandler = async (fileId:any, fieldName:string, key:any) => {
    try {
      const { status } = await del(FILE_API.delete(fileId));
      if(status) {
       if(fieldName === 'additionalImage') {
          const additionalImagesList:any = [...additionalImages];
          const listAfterRemove:any = filter(additionalImagesList, (additionalImage)=>{
            return additionalImage.key !== key;
          });

          setAdditionalImages(listAfterRemove);
        }
      }
    } catch (error) {
      console.log('error:', error);
    }
  };


  const onSubmitPhotoHandler = async () => {
    if(isValidPhotoForm()) {
      const additImageOne:any = getAdditionalImage('additionalImageOne');
      const additImageTwo:any = getAdditionalImage('additionalImageTwo');
      const additImageThree:any = getAdditionalImage('additionalImageThree');
      const additImageFour:any = getAdditionalImage('additionalImageFour');

      if(isEdit && article.uid) {
        const updatedArticle = {
          ...article,
          front_image: frontImage,
          back_image: backImage,
          frontImageFileId,
          backImageFileId,
          width,
          height,
          additional_image_1: additImageOne?.url || null,
          additional_image_2: additImageTwo?.url || null,
          additional_image_3: additImageThree?.url || null,
          additional_image_4: additImageFour?.url || null,
          additionalImageOneFileId: additImageOne?.fileId || null,
          additionalImageTwoFileId: additImageTwo?.fileId || null,
          additionalImageThreeFileId: additImageThree?.fileId || null,
          additionalImageFourFileId: additImageFour?.fileId || null
        };
        navigate(fromPathname, {state: updatedArticle});
      } else {
        const request = {
          ...selectRequest,
          front_image: frontImage,
          back_image: backImage,
          userId: user?.id || null,
          frontImageFileId,
          backImageFileId,
          width,
          height,
          articleUid: selectRequest?.uid,
          additional_image_1: additImageOne?.url || null,
          additional_image_2: additImageTwo?.url || null,
          additional_image_3: additImageThree?.url || null,
          additional_image_4: additImageFour?.url || null,
          additionalImageOneFileId: additImageOne?.fileId || null,
          additionalImageTwoFileId: additImageTwo?.fileId || null,
          additionalImageThreeFileId: additImageThree?.fileId || null,
          additionalImageFourFileId: additImageFour?.fileId || null
        };

        dispatch(addToRequest(request));
        dispatch(clearSelectRequest());

        const responseLocal = await post(CART_API.create(), request, {isArray: true});
        if (responseLocal) {
          toast.success("Successfully Added to Cart!", toastValues);
        } else {
          toast.error("Some things went wrong!", toastValues);
        }
        navigate("/your-article");
      }
    } else {
      if(isCheckFormValidation) setIsCheckFormValidation(false);
      setTimeout(()=>{
        setIsCheckFormValidation(true);
      })
    }
  };

  const onChangeInputFieldHandler = (e:any, fieldName:string) =>{
    const fieldValue = e.currentTarget.value;

    if(fieldValue <= 0 || fieldValue > 100 ) {
      toast.error("Sorry! Please, set within 1 to 100", toastValues);
    } else {
      if(fieldName === 'height') setHeight(fieldValue);
      else if(fieldName === 'width') setWidth(fieldValue);
    }
  }

  const getAdditionalImage = (key:any) => {
    if(key && additionalImages.length) {
     return additionalImages.find((image:any)=> image.key === key);
    } else return null;
  };
  // End events handler

  return (
      <>
        <Container fluid className="background-white container-min-height">
          <Row>
            <Col lg={3} md={3} xs={12}> </Col>
            <Col lg={6} md={6} xs={12}>
              <Card.Title className="page-title">{t('image_upload.title_add_photos')}</Card.Title>
              <Card.Subtitle className="text-center">
                {t('image_upload.image_upload_page_subtitle')}
              </Card.Subtitle>

              {/* Start product category */}
              <Row className="my-4">
                <Col lg={3} md={4} xs={6} className="mb-4">
                  { isShowfrontImage ? <div className={`add-photo-box ${isCheckFormValidation && !frontImage?'invalid':''}`}>
                    {
                      frontImgLoading? <ImageUploadLoader height={130} width={130} />
                          :<img
                              className="selected-img"
                              src={frontImage || photoPlaceholderFront}
                          />
                    }
                    <div className="photo-box-title"> {t('image_upload.img_front')} </div>
                      <PhotoUploader
                          elId="front-image"
                          isImageLoaded={frontImage}
                          selectedImage={(file: any) => onUploadImgHandler(file, 'frontImage')}
                          onRotted={() => console.log("rotted trigger")}
                          onReset={() => onRemoveImgHandler(frontImageFileId, 'frontImage')}
                      />
                      <small className="invalid-text">{t('form.invalid_message')}</small>
                    </div>:null}
                </Col>
                <Col lg={3} md={4} xs={6} className="mb-4">
                  { isShowbackImage ? <div className={`add-photo-box ${isCheckFormValidation && !backImage?'invalid':''}`}>
                    {
                      backImgLoading? <ImageUploadLoader height={130} width={130} />
                          : <img
                              className="selected-img"
                              src={backImage || photoPlaceholderBack}
                          />
                    }
                    <div className="photo-box-title"> {t('image_upload.img_back')} </div>
                    <PhotoUploader
                        elId="back-image"
                        isImageLoaded={backImage}
                        selectedImage={(file: any) => onUploadImgHandler(file, 'backImage')}
                        onRotted={() => console.log("rotted trigger")}
                        onReset={() => onRemoveImgHandler(backImageFileId, 'backImage')}
                    />
                     <small className="invalid-text">{t('form.invalid_message')}</small>
                  </div> : null}
                </Col>

                {
                  additionalImages.map((additionalImage:any, index:number) => {
                    return (
                      <Col key={`${additionalImage.key}_${index}`} lg={3} md={4} xs={6} className="mb-4">
                        <div className="add-photo-box">
                          <img
                              className="selected-img"
                              src={additionalImage.url || photoPlaceholder}
                          />
                          <div className="photo-box-title"> {t('image_upload.add_photo')} </div>
                          <PhotoUploader
                              elId={additionalImage.key}
                              isImageLoaded={additionalImage.url}
                              selectedImage={(file: any) => onUploadImgHandler(file, additionalImage.key)}
                              onRotted={() => console.log("rotted trigger")}
                              onReset={() => onRemoveAdditionalImgHandler(additionalImage.fileId, "additionalImage", additionalImage.key)}
                          />
                        </div>
                      </Col>
                    )
                  })
                }

                {/* Start add new additional Images */}
                {
                  additionalImages.length < 4? <>
                    <Col lg={3} md={4} xs={6} className="mb-4">
                      { isShowAdditionalImage ? <div className="add-photo-box">
                        {
                          additionalImageLoading? <ImageUploadLoader height={130} width={130} />
                              : <img
                                  className="selected-img"
                                  src={additionalImage || photoPlaceholder}
                              />
                        }
                        <div className="photo-box-title"> {t('image_upload.add_photo')} </div>
                        <PhotoUploader
                            elId="additionalImageFour"
                            isImageLoaded={additionalImage}
                            selectedImage={(file: any) => onUploadAdditionImgHandler(file, 'additionalImage')}
                            onRotted={() => console.log("rotted trigger")}
                            onReset={() => {}}
                        />
                      </div> : null}
                    </Col>
                  </>:''
                }
                {/* End add new additional Images */}

              </Row>
              {/* End Images      */}

              {/* Start height/width section  */}
              {
                isShowHeightWidth? <>
                  <div className="text-center my-4">
                    <h5>{t("additional_information_required")}</h5>
                  </div>
                  <Row className="my-4">
                    <Col lg={6} md={6} xs={6} className="mb-2">
                     <div className={`${isCheckFormValidation && !width?'invalid':''}`}>
                        <InputGroup className="mb-1">
                          <Form.Control
                              type="number"
                              min="1"
                              max="100"
                              value={width}
                              placeholder={t('bag_width') || ''}
                              aria-label="Recipient's username"
                              aria-describedby="basic-addon2"
                              onChange={(e:any)=>onChangeInputFieldHandler(e, 'width')}
                          />
                          <InputGroup.Text id="photoWidth"
                          >cm</InputGroup.Text>
                        </InputGroup>
                        <small className="invalid-text">{t('form.invalid_message')}</small>
                      </div>
                    </Col>
                    <Col lg={6} md={6} xs={6} className="mb-3">
                     <div className={`${isCheckFormValidation && !height?'invalid':''}`}>
                      <InputGroup className="mb-1">
                        <Form.Control
                            type="number"
                            min="1"
                            max="100"
                            value={height}
                            placeholder={t('bag_height') || ''}
                            aria-label="Recipient's username"
                            aria-describedby="basic-addon3"
                            onChange={(e:any)=>onChangeInputFieldHandler(e, 'height')}
                        />
                        <InputGroup.Text id="photoHeight">cm</InputGroup.Text>
                      </InputGroup>
                      <small className="invalid-text">{t('form.invalid_message')}</small>
                     </div>
                    </Col>
                  </Row>
                </>:<></>
              }
              {/* End height/width section  */}

              {/* <p className="text-center py-3 pb-5">
                {t('image_upload.img_message')}
              </p> */}
              <div className="how-to-form-footer pt-3 pb-5 px-3 d-flex justify-content-center">
                {/* <Button
                    onClick={() => navigate(-1)}
                    className="round-btn"
                    variant="dark"
                    size="sm"
                >
                  {t('return')}
                </Button> */}
                <Button
                    // disabled={!isValidPhotoForm()}
                    onClick={() => onSubmitPhotoHandler()}
                    className="round-btn"
                    variant="dark"
                    size="sm"
                >{t('continue_to_the_summary')}</Button>
              </div>
            </Col>
            <Col lg={3} md={3} xs={12}> </Col>
          </Row>
        </Container>
        <PhotoRestrictionsModal
            show={isShowTermModal}
            onHide={() => setIsShowTermModal(!isShowTermModal) }
        />

        {/* Start load components  */}
      </>
  );
};

export default PhotoPage;