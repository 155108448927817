import { Form, Button, Row, Col } from "react-bootstrap";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';

// Start import images
import {addToAddressList, clearAddress} from "../store/address/actions";
import { toastValues } from "common/toastValues";
import { post } from 'helpers/api_helpers';
import { SHIPMENT_API } from "constants/apiEndPoints/bnsApi/shipmentEndpoints";
import { addToShipment } from "store/shipment/actions";

const ShippingPage = (props:any) => {
    const { goBack, goNext, request } = props;
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { authUser } = useSelector(({ Login }: { Login: any }) => Login);
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [address, setAddress] = useState<string>("");
    const [streetName, setStreetName] = useState<string>("");
    const [streetNo, setStreetNo] = useState<string>("");
    const [postalCode, setPostalCode] = useState<string>("");
    const [location, setLocation] = useState<string>("");

    const addAddress = () => {
        const addressData = {
            firstName: firstName,
            lastName: lastName,
            address: address,
            streetName: streetName,
            streetNo: streetNo,
            postalCode: postalCode,
            location: location
        };

        dispatch(clearAddress());
        dispatch(addToAddressList(addressData));
        createShippingLabel()
        goNext(2);
    };

    const createShippingLabel = async () => {
        const shipmentData = {
            userId: authUser?.id,
            email: authUser?.email,
            requestRefId: request,
            lastName,
            streetName,
            streetNo,
            postalCode,
            location,
        }

        try {
            const response = await post(SHIPMENT_API.create_shipping_lavel(), shipmentData);
            dispatch(addToShipment(response));
        } catch (error:any) {
            toast.error(error?.message, toastValues);
        }

    };

    const isInvalidForm = () => {
        return !(firstName && address && streetName && streetNo && postalCode && location);  
    }
    // End events handlers

    return (
        <>
            <Row>
                <Col lg={3} md={3} xs={12}> </Col>
                <Col lg={6} md={6} xs={12}>
                    <div className="text-center pb-5">{t('shipment_details')}</div>
                    <div className="shipping">
                        <div>
                            <Form>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="vorname">
                                            <Form.Control type="text" 
                                            placeholder={`${t('first_name')}`}                   
                                            value={firstName}
                                            onChange={(e)=>setFirstName(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="nachname">
                                            <Form.Control type="text" 
                                            placeholder={`${t('last_name')}`}
                                                            value={lastName}
                                                            onChange={(e)=>setLastName(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="adresszusatz">
                                            <Form.Control type="text" 
                                            placeholder={`${t('address_supplement')}`}
                                                            value={address}
                                                            onChange={(e)=>setAddress(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="sreetName">
                                            <Form.Control type="text" 
                                            placeholder={`${t('street_name')}`}
                                                            value={streetName}
                                                            onChange={(e)=>setStreetName(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="straße">
                                            <Form.Control type="text" 
                                            placeholder={`${t('street_no')}`}
                                                            value={streetNo}
                                                            onChange={(e)=>setStreetNo(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="postleitzahl">
                                            <Form.Control type="text" 
                                            placeholder={`${t('postal_code')}`}
                                                            value={postalCode}
                                                            onChange={(e)=>setPostalCode(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3" controlId="ort">
                                            <Form.Control type="text" 
                                            placeholder={`${t('location')}`}
                                                            value={location}
                                                            onChange={(e)=>setLocation(e.target.value)}/>
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="d-flex justify-content-between py-2">
                                            <Button className="round-btn" variant="outline-dark" onClick={() => goBack(0)}>{t('return')}</Button>
                                            <Button disabled={isInvalidForm()} className="round-btn" variant="dark" onClick={() => addAddress()}>{t('continue')}</Button>
                                        </div>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </Col>
                <Col lg={3} md={3} xs={12}> </Col>
            </Row>
        </>
    );
};

export default ShippingPage;