// Start Import package
import Card from 'react-bootstrap/Card';

// Start import helpers
import {getCategoryName } from 'helpers/common_helper';

// Start import images
import {
  photoPlaceholderFront,
  closeIcon,
  penIcon,
} from 'assets/image/image';
import { useEffect, useState } from 'react';

const ArticleCard = (props:any) => {
  const { article, onRemove, onEdit, categories } = props;
  const [defaultImage, setDefaultImage] = useState(photoPlaceholderFront);
  const [brandName, setBrandName] = useState<any>('');
  const [mainCategoryName, setMainCategoryName] = useState<any>('');
  const [majorCategoryName, setMajorCategoryName] = useState<any>('');

  useEffect(()=>{
    setDefaultImage(article.front_image);
    setBrandName(article.brand);
    const categoryNames:any = getCategoryName(categories, article, 'all');
    setMainCategoryName(categoryNames.mainCategoryName || article.main_category);
    setMajorCategoryName(categoryNames.majorCategoryName || article.major_category);
  }, [article]);

  const totalImage = (data: any) => {
    let count = 0;
    const imgArr = [data.front_image,data.back_image, data.additional_image_1, data.additional_image_2, data.additional_image_3, data.additional_image_4 ]

    imgArr.forEach(data => {
      if(data){
        count++;
      }
    })
    return count;
  };

  return (
      <Card className="article-card">
        <Card.Body>
          <div className="article-card-left">
            <Card.Title>{brandName}</Card.Title>
            <Card.Text> {mainCategoryName} /  {majorCategoryName} </Card.Text>
            <div className="article-actions">
              <img className="article-action-btn" src={penIcon} title="Rotted" onClick={()=>onEdit(article.articleUid)} />
              <img className="article-action-btn" src={closeIcon} title="Remove" onClick={()=>onRemove(article.articleUid)} />
            </div>
          </div>
          <div className="card-right image-div">
            <img src={defaultImage} />
            <div className="img-count-div">{totalImage(article)}</div>
          </div>

        </Card.Body>
      </Card>
  );
};

export default ArticleCard;
