// Start Import package
import Card from 'react-bootstrap/Card';

// Start import images
import {
  closeIcon,
  rottedIcon,
} from 'assets/image/image';
import { useTranslation } from 'react-i18next';

const PhotoUploader = (props:any) => {
  const { elId, btnText, selectedImage, isImageLoaded, onReset, onRotted } = props;
  const { t } = useTranslation();

  const onCompleteUploadImage = (event:any): void => {
    const nativeEvent = event.nativeEvent.target as HTMLInputElement;
    const targetEvent = event.target as HTMLInputElement;
    if (targetEvent.files && targetEvent.files[0]) {
      const imageFile = targetEvent.files[0];
      selectedImage(imageFile);
      // eslint-disable-next-line no-param-reassign
      nativeEvent.value = "";
    }
  };

  return (
      <div className="uploader-box">
        <input className="uploader-field" accept="image/*" id={elId} type="file" onChange={(e)=>onCompleteUploadImage(e)} />
        {
          isImageLoaded? <div className="uploader-actions">
            {/* <img className="uploader-action-btn" src={rottedIcon} title="Rotted" onClick={()=>onRotted()} /> */}
            <img className="uploader-action-btn" src={closeIcon} title="Remove" onClick={()=>onReset()} />
          </div> : <label htmlFor={elId} className="round-btn btn btn-sm label-round-btn">{btnText || `${t('image_upload.choose')}`}</label>
        }

      </div>
  );
};

export default PhotoUploader;
