import { Container, Card, Button, Spinner, Col, Row} from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { v1 as uuidv1 } from 'uuid';
import { useTranslation } from 'react-i18next';

// Start import helpers
import { addToRequest, removeFromRequest, updateRequest, setPartnerParams, isReadRequestCondition } from 'store/request/actions';
import { actionConfirm, getCategoryName } from 'helpers/common_helper';
import { toastValues } from 'common/toastValues';
import { del, get, getBnsApi, post, postBnsApi } from 'helpers/api_helpers';
import { REQUEST_BNS_API } from 'constants/apiEndPoints/bnsApi/requestEndpoints';
import { REQUEST_API } from 'constants/apiEndPoints/requestEndpoints';
import { CATEGORY_API } from 'constants/apiEndPoints/bnsApi/categoriesEndpoints';

// Start import components
import ArticleCard from 'components/common/ArticleCard';
import { CART_API } from 'constants/apiEndPoints/bnsApi/cartEndpoints';
import { FILE_API } from 'constants/apiEndPoints/fileEndpoints';
import { requestUid } from 'store/request/actions';

const ArticlePage = () => {
    //load/instance of hooks
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();

    //Instance variable
    const location = useLocation();
    const { requests } = useSelector(({ Request }: { Request: any }) => Request);
    const { authUser } = useSelector(({ Login }: { Login: any }) => Login);
    const request = useSelector(({ Request }: { Request: any }) => Request);

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [user, setUser] = useState<any>({})
    const [isAuth, setIsAuth] = useState<boolean>(true);
    const [mainCategories, setMainCategories] = useState<any>([]);

    //Start react effect life cycle
    useEffect(()=>{
        setUser(authUser);
        setIsAuth(!!authUser);
        fetchCategory();
    }, [authUser]);

    useEffect(()=>{
        checkRequestsData()
    }, [authUser]);
    //End react effect life cycle

    //reqestid change handler
    const handleClick = () => {
        const newRequestId = uuidv1();
        if (request.uid) {
            dispatch(requestUid(newRequestId));
        }
    };

    //reqestid change handler

    //Start fetching handler/helpers
    const checkRequestsData = async () => {
        if(!requests.length && authUser){
            setLoading(true);
            try {
                if(request?.uid) {
                    const userId = await authUser?.id;
                    const responseCart = await get(CART_API.get_byUser_id(userId));
                    if(responseCart.length > 0) {
                        responseCart.forEach((request:any)   => {
                            dispatch(addToRequest(request));
                        });
                    }
                }
                setLoading(false);
            } catch (error:any) {
                toast.error("Some things went wrong!", toastValues);
                setLoading(false);
            }
        }
    };

    const fetchCategory= async () => {
        setLoading(true);
        try {
            const { data } = await getBnsApi(CATEGORY_API.fetch_all());
            setMainCategories(data.main_categories);
            setLoading(false);
        } catch (error:any) {
            toast.error(error.message, toastValues);
            setLoading(false);
        }
    };
    //End fetching handler/helpers

    //Start Events handler/helpers
    const onEdit = (id:string) => {
        navigate(`/edit-article/${id}`)
    };

    const onRemove = async (requestArticle:any) => {
        const articleUid = requestArticle.articleUid;
        const frontImageFileId = requestArticle.frontImageFileId;
        const backImageFileId = requestArticle.backImageFileId;
        const additionalImageOneFileId = requestArticle?.additionalImageOneFileId;
        const additionalImageTwoFileId = requestArticle?.additionalImageTwoFileId;
        const additionalImageThreeFileId = requestArticle?.additionalImageThreeFileId;
        const additionalImageFourFileId = requestArticle?.additionalImageFourFileId;

        const config = {
            title:`${t('confirm_delete.are_you_sure')}`,
            text: `${t('confirm_delete.you_cannot_undo_this')}`,
            confirmButtonText: `${t('confirm_delete.confirm_button')}`,
            cancelButtonText: `${t('confirm_delete.interrupt_button')}`
        };

        const imgArr = [frontImageFileId, backImageFileId];
        if(additionalImageOneFileId) imgArr.push(additionalImageOneFileId);
        if(additionalImageTwoFileId) imgArr.push(additionalImageTwoFileId);
        if(additionalImageThreeFileId) imgArr.push(additionalImageThreeFileId);
        if(additionalImageFourFileId) imgArr.push(additionalImageFourFileId);
        // confirm modal
        actionConfirm(config).then( async ({isConfirmed}:any)=>{
            if(isConfirmed) {
                setLoading(true);
                try {
                    // image delete loop start here
                    for (let i = 0; i < imgArr.length; i++) {
                        const imgId = imgArr[i];
                        if (imgId) {
                            const status = await del(FILE_API.delete(imgId));
                            console.log(status,"img status");
                        }
                    }
                    // image delete loop end here
                    const deleteSingleCart = await get(CART_API.delete(articleUid));
                    if(deleteSingleCart?.status === "true"){
                        dispatch(removeFromRequest(articleUid));
                        toast.success("Successfully Delete from Cart!", toastValues);
                    };
                    setLoading(false);
                } catch (error:any) {
                    toast.error("Some things went wrong!", toastValues);
                    setLoading(false);
                }
            }
        });
    };

    const sendRequest = async () => {
        if(authUser){
            setIsLoading(true);

            try {
                const allArticles:any = [];
                if(requests.length) {
                    requests.forEach((article:any) => {
                        const categoryNames:any = getCategoryName(mainCategories, article, 'all');

                        allArticles.push({
                            ...article,
                            main_category: categoryNames.mainCategoryName,
                            major_category: categoryNames.majorCategoryName,
                            minor_category: categoryNames.minorCategoryName,
                        });
                    });

                    const response = await postBnsApi(REQUEST_BNS_API.purchase_requests(), {"articles": allArticles});
                    if(response.status == 'CREATED') {
                        const getArticles = await getBnsApi(REQUEST_BNS_API.fetch_request_by_id(response.data.reference_number));

                        requests.filter((article: any) => {
                            article.userId = user.id;
                            article.refId = response.data.reference_number;
                            // article.BnSId = response.data.id;
                            article.createdBy = user?.id;
                            article.updatedBy = user?.id;
                            return article;
                        });

                        const newAddedArticles:any = [];
                        if(requests.length) {
                            requests.forEach((artical:any, i:any) => {

                                newAddedArticles.push({
                                    ...artical,
                                    bnsArticleId: getArticles?.data?.articles[i]?.id,
                                    article_code: getArticles?.data?.articles[i]?.article_code,
                                    state: getArticles?.data?.articles[i]?.state,
                                    categoryNames: getArticles?.data?.articles[i]?.categoryNames,
                                    purchase_price: getArticles?.data?.articles[i]?.purchase_price,
                                });
                            })}

                        const newRequest = {
                            articles: newAddedArticles,
                            isReadCondition: request?.isReadCondition,
                            refId: response.data.reference_number,
                            uid: request.uid,
                            createdBy: user?.id,
                            updatedBy: user?.id,
                            userId: user?.id,
                            partnerId: request?.partnerInfo?.partnerId || null,
                            partnerCoupon: request?.partnerInfo?.partnerCoupon || null,
                        };

                        const newRequestResponse:any = await post(REQUEST_API.create(), newRequest);

                        if (newRequestResponse?.id) {
                            toast.success("Successfully created!", toastValues);
                            // delete all cart from local server
                            await del(CART_API.delete_all(authUser?.id));
                            dispatch(updateRequest([]));
                            dispatch(requestUid(''));
                            dispatch(isReadRequestCondition(false));
                            dispatch(setPartnerParams({}));
                            handleClick()
                        } else {
                            toast.error("Some things went wrong!", toastValues);
                        }

                        setIsLoading(false);
                        navigate('/welcome');
                    } else {
                        toast.error(response.message, toastValues);
                    }

                }
            } catch (error:any) {
                setIsLoading(false);
                toast.error(error.message, toastValues);
            }

        } else {
            return navigate('/login', {state:location})
        }
    }
    //End Events handler/helpers

    return (
        <Container fluid className="background-white container-min-height">
            <Card.Title className="page-title">{t('your_articles')}</Card.Title>

            {/* Start product category */}
            { loading? <div className="content-middle">
                <Spinner animation="border" variant="warning" />
            </div> : <Row className="my-4 d-flex justify-content-center">
                {
                    requests.map((request:any, index:number)=>{
                        return (<Col key={`${index}_${request.id}`} lg={3} md={6} xs={12}>
                            <ArticleCard onEdit={(onEdit)} onRemove={() => onRemove(request)} article={request} categories= { mainCategories } />
                        </Col>)
                    })
                }
            </Row>
            }
            {/* End product category */}

            <Row className="mb-4">
                <Col lg={12} md={12} xs={12}>
                    <div className="add-article-box" onClick={()=>navigate('/categories')}>
                        <i className="fa fa-plus icon"></i>
                        <p>{t('add_article')}</p>
                    </div>
                </Col>
            </Row>

            <div className="how-to-form-footer pt-3 pb-5 px-3 d-flex justify-content-end">
                {/* <Button onClick={() => navigate(-1)} className="round-btn" variant="dark" size="sm"> zurück </Button> */}
                {authUser ? <Button className={`green-btn ${isLoading?'disabled':''}`} onClick={()=> isLoading? '':sendRequest()} variant="dark" size="sm">
                {isLoading ? (
                    <><Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" /> {t('sending')}...</>
                    ) : ( t('send_request_now') )}
                    </Button> :
                    <Button onClick={sendRequest} className="round-btn" variant="dark" size="sm"> {t('without_login')}</Button>}
            </div>

        </Container>
    );
};

export default ArticlePage;
