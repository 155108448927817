import jwtDecode from 'jwt-decode';
import { call, put, takeEvery } from 'redux-saga/effects';
import { apiError, loginSuccess, logoutUserSuccess } from './actions';
import { LOGIN_USER, LOGOUT_USER } from './actionsTypes';
import { jwtLogin } from './apiCalls';

function* loginUser({ payload: { user, navigate } }: any): any {
  try {
    const response = yield call(jwtLogin, {
      userId: user.userId,
      password: user.password
    });
    const userInfo:any = response && response.accessToken ? jwtDecode(response.accessToken) : null;
    console.log(userInfo);
    localStorage.setItem('authUser', JSON.stringify(response));
    yield put(loginSuccess(userInfo));
    navigate('/');
  } catch (error: any) {
    let message;
    const errorStatus = error?.response?.status;
    if (errorStatus) {
      switch (error.response.status) {
        case 404:
          message = 'Sorry! the page you are looking for could not be found';
          break;
        case 500:
          message = 'Sorry! something went wrong, please contact our support team';
          break;
        case 401:
          message = 'Invalid credentials';
          break;
        default:
          message = error[1];
          break;
      }
    }

    if (!errorStatus && error.code === 'ERR_NETWORK') {
      message = 'Netword Error!';
    }

    yield put(apiError(message));
  }
}
function* logOut({ payload: { navigate } }: any): any {
  yield put(logoutUserSuccess());
  navigate('/login');
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logOut);
}
export default authSaga;
